import React, { useState } from "react";
import Box from "@mui/material/Box";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
// import { FaUser } from "react-icons/fa";
import { IoMdMail } from "react-icons/io";
import { FaSchool } from "react-icons/fa";
import { IoIosArrowDown } from "react-icons/io";
import { Link, useNavigate } from "react-router-dom";
// import { BiHide } from "react-icons/bi";

function Classroom() {
  const [uploadPercentage, setUploadPercentage] = useState(0);
  const [fileName, setFileName] = useState("");
  const [uploading, setUploading] = useState(false);
  const navigate = useNavigate();

  const handleSendOtp = () => {
  
    navigate('/Otp', {
      state: { type: 'ClassroomOtp', data: { button:'Submit' } },
    });
  };
  // file upload
  const handleFileChange = (event) => {
    const file = event.target.files[0];

    if (file) {
      setFileName(file.name);
      setUploading(true);
      const reader = new FileReader();

      reader.onprogress = (e) => {
        if (e.lengthComputable) {
          const percentComplete = Math.round((e.loaded / e.total) * 100);
          setUploadPercentage(percentComplete);
        }
      };

      reader.onload = () => {
        setUploadPercentage(100); // Set to 100% when done
        setUploading(false);
      };

      reader.onerror = () => {
        console.error("File reading error");
        setUploadPercentage(0); // Reset on error
        setUploading(false);
      };

      reader.readAsText(file);
    }
  };

  const handlePause = () => {
    // Logic to pause the file reading/upload process
  };

  const handleCancel = () => {
    // Logic to cancel the file reading/upload process
    setUploadPercentage(0);
    setFileName("");
    setUploading(false);
  };

  // form handling
  const [formData, setFormData] = useState({
      schoolName: "",
      schoolDist: "",
      email: "",
      csv:'',
   
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission
    console.log(formData);
  };

  return (
    <div>
      <form>
        <Box
          component="form"
          sx={{
            "& .MuiTextField-root": { marginTop: 1.5 },
            "& .MuiInput-underline:hover:before": {
              borderBottom: "none", // No border bottom on hover
            },
            "& .MuiInputBase-input::placeholder": {
              fontSize: "14px", // Adjust placeholder font size here
            },
            "& .MuiFormLabel-root": {
              padding: "15px 3px 0px 21px", // Adjust padding as needed
              fontSize: "17px",
              color: "black",
            },
            "& .MuiInputBase-root": {
              padding: "10px 0px 3px 15px", // Optional: Adjust input padding as needed
            },
            "& .MuiInput-underline:before": {
              borderBottom: "none",
            },
            "& .MuiInput-underline:after": {
              borderBottom: "none",
            },
            "& .MuiInput-underline:hover:before": {
              borderBottom: "none",
            },
          }}
          noValidate
          autoComplete="off"
        >
          <TextField
            id="input-with-icon-textfield"
            label="School Name"
            placeholder="John High School"
            // value={formData.schoolName}
            // onChange={handleChange}
            InputLabelProps={{
              shrink: true,
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end" sx={{ margin: "0px 20px" }}>
                  <FaSchool />
                </InputAdornment>
              ),
            }}
            variant="standard"
            className="bg-white w-full rounded-xl pt-10"
          />
          <TextField
            id="input-with-icon-textfield"
            label="School District"
            placeholder="Clark County District"
            // value={formData.schoolDist}
            // onChange={handleChange}
            InputLabelProps={{
              shrink: true,
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end" sx={{ margin: "0px 20px" }}>
                  <IoIosArrowDown />
                </InputAdornment>
              ),
            }}
            variant="standard"
            className="bg-white w-full rounded-xl pt-10"
          />
          <TextField
            id="input-with-icon-textfield"
            label="Email"
            placeholder="johndoe@gmail.com"
            // value={formData.email}
            // onChange={handleChange}
            InputLabelProps={{
              shrink: true,
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end" sx={{ margin: "0px 20px" }}>
                  <IoMdMail />
                </InputAdornment>
              ),
            }}
            variant="standard"
            className="bg-white w-full rounded-xl pt-10"
          />
        </Box>

        <div className="flex justify-center items-center px-10 py-9 mt-8 text-sm font-medium text-black rounded-2xl border border-dashed bg-white bg-opacity-70 border-stone-300 max-md:px-5 max-md:max-w-full">
          <input
            type="file"
            accept=".csv"
            id="fileInput"
            className="hidden"
            value={formData.csv}
            onChange={handleFileChange}
          />
          <label
            htmlFor="fileInput"
            className="flex flex-col items-center cursor-pointer "
          >
            <div className="flex gap-3.5">
              <img
                loading="lazy"
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/88dc460c7be44fa1707a572cbc56c7e8002405810f6205e4e9d260fb89f58398?apiKey=aa75e9b74ffc49ffb5206435a70fee82&&apiKey=aa75e9b74ffc49ffb5206435a70fee82"
                className="shrink-0 w-7 aspect-square"
              />
              <div className="flex-auto my-auto">Add .csv file</div>
            </div>
          </label>
        </div>
        {fileName && (
          <div className="w-full mt-4">
            <div className="flex items-center">
              <img
                src="/images/auth/xml.png"
                alt="file icon"
                className="w-6 h-6"
              />
              <span className="ml-2 text-sm">{fileName}</span>
            </div>
            <div className="flex gap-5 my-auto">
              <div className="relative my-auto mt-2 w-full h-4 bg-gray-200 rounded-full overflow-hidden">
                <div
                  className="absolute top-0 left-0 h-full bg-gradient-to-r from-blue-500 to-purple-500"
                  style={{ width: `${uploadPercentage}%` }}
                ></div>
                <span className="absolute top-0 left-0 right-0 text-center text-white text-xs">{`Uploading... ${uploadPercentage}%`}</span>
              </div>
              <div className="flex justify-end items-center mt-1 my-auto space-x-1">
                <button
                  onClick={handlePause}
                  className="text-gray-500 hover:text-gray-700"
                >
                  {/* Pause Icon */}
                  <img
                    src="/images/auth/pause.png"
                    alt="file icon"
                    className="w-6 "
                  />
                </button>
                <button
                  onClick={handleCancel}
                  className="text-red-500 hover:text-red-700"
                >
                  {/* Cancel Icon */}
                  <img
                    src="/images/auth/cancel.png"
                    alt="file icon"
                    className="w-7 "
                  />
                </button>
              </div>
            </div>
          </div>
        )}

        <div className="flex gap-2 justify-center  mt-6 max-w-full">
          <div className="flex flex-col justify-center  p-0.5">
            <input type="checkbox" className="w-5 h-5" id="" name="" value="" />
          </div>
          <div className="text-base leading-6 ">
            I agree with
            <span className="text-blue-600  pl-1 cursor-pointer">
            <Link to="/terms-conditions">terms & conditions</Link> 
            </span>
          </div>
        </div>
        <button  onClick={handleSendOtp}
          style={{
            background: "linear-gradient(90deg, #1D68FF 0%, #9A52DE97 100%)",
          }}
          className="px-4 py-3.5 mt-5 w-full text-base text-center text-white rounded-2xl max-md:px-5 max-md:max-w-full"
        >
          Send OTP
        </button>
        <div className="flex justify-center mt-7  text-base text-slate-950">
          Already have an account?
          <span className="font-medium pl-1 text-blue-600 cursor-pointer">
            Login
          </span>
        </div>
      </form>
    </div>
  );
}

export default Classroom;
