import * as React from "react";
import { GoDownload } from "react-icons/go";

function Report({ setActiveTab }) {
  const [showModal, setShowModal] = React.useState(true);
  
  return (
    <div style={{boxShadow:"0px 4px 19px 0px rgba(0, 0, 0, 0.05)"}} className="py-5 pr-3 pl-6 mt-6 bg-white rounded-xl max-md:pl-5 max-md:max-w-full">
      <div className="flex gap-5 max-lg:flex-col">
        <div className="flex flex-col w-[62%] max-md:ml-0 max-lg:w-full">
          <div className="flex flex-col mt-2.5 w-full max-md:mt-10 max-md:max-w-full">
            <div className="self-start text-4xl max-md:text-3xl tracking-[1px] font-bebas uppercase text-[#1D68FF]">
              Your talent report
            </div>
            <img
              loading="lazy"
              src="/images/dashboard/survey/report.png"
              className="object-contain mt-5 w-full rounded-xl aspect-[2.94] max-md:max-w-full"
            />
            <div className="self-start mt-8 text-3xl font-bebas max-md:text-2xl tracking-[1px] text-green-600">
              You are an Adapter
            </div>
            <div className="mt-2 text-base leading-7 text-neutral-400 max-md:max-w-full">
              Adapters are unique and they have the ability of being able to
              adapt to the needs of environment. The shaded circles and scores
              below indicate your strength level as a percentage of the
              population with the corresponding descriptor in the same color.
            </div>
            <div className="self-start mt-5 text-xl font-medium text-slate-950">
              Your Contrasting Work Talent
            </div>
            <div className="mt-2 text-base leading-7 text-neutral-400 max-md:max-w-full">
              The shaded circles and scores below indicate your strength level
              as a percentage of the population with the corresponding
              descriptor in the same color.
            </div>
            <div className="flex flex-wrap gap-4 self-end mt-6 max-w-full text-base text-center max-md:mt-3 max-md:mr-2.5">
              <button   onClick={() => setActiveTab(1)}  className="gap-2.5 self-center px-10  text-blue-600 py-3.5 text-blue border-[1px] border-blue-600 rounded-2xl">
                Go To Dashboard
              </button>
              <button className="gap-1.5 flex self-center px-10  py-3.5 text-white rounded-2xl bg-[linear-gradient(90deg,#1D68FF_50%,#9A52DE_97.52%)] ">
                <GoDownload className="my-auto " />
                <div>Download Report</div>
              </button>
            </div>
          </div>
        </div>
        <div className="flex flex-col w-[38%] mt-14 max-lg:ml-0 max-lg:w-full">
          {result.map((index) => {
            return (
                <div style={{boxShadow:"0px 4px 19px 0px rgba(0, 0, 0, 0.05)"}} className=" mt-3 bg-white rounded-xl max-md:pr-5 max-md:mr-2 p-3 max-md:max-w-full">
                  <div className="flex gap-5">
                    <div className="flex flex-col  max-md:w-full">
                      <img
                        loading="lazy"
                        src={index.img}
                        className="w-16"
                      />
                    </div>
                    <div className="flex flex-col max-md:w-full">
                      <div className="flex flex-col my-auto text-base text-black ">
                        <div className="self-start font-semibold">
                          <span className="font-medium text-green-600">
                            {index.result}
                          </span>
                          <span className="font-medium text-black">vs</span>{" "}
                          <span className="font-medium text-blue-600">
                            {index.relationship}
                          </span>
                        </div>
                        <div  >
                          {index.info}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
          
            );
          })}
        </div>
      </div>
      {showModal ? (
        <>
            <div className="justify-center items-center  flex overflow-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-auto my-6 mx-auto max-w-3xl">
              {/*content*/}
              <div className="border-0 rounded-lg shadow-lg mx-5 relative flex flex-col bg-white outline-none focus:outline-none">
                <div className=" px-10 py-5 max-md:px-3">
                  <div className="flex justify-center">
                    <img
                      src="/images/dashboard/exercise.png"
                      alt="not found"
                      className=""
                    />
                  </div>
                  <div>
                    <div className="z-10 self-center uppercase flex justify-center font-bebas tracking-[1px] text-3xl max-md:text-2xl mt-4 text-green-600">
                    Complete the Journey’s map
                    </div>
                    <div className="mx-auto text-center px-5 max-md:px-2 mt-3 text-base tracking-tight self-center leading-4 text-neutral-800">
                      Confused about your future career? Get to know about your
                      superpowers by taking the test.
                    </div>
                  </div>
                  <div className="flex justify-center gap-3 mt-5 w-full text-sm">
                    <button
                      onClick={() => setShowModal(false)}
                      className="gap-2.5 self-stretch px-12 py-3.5 text-blue-600 whitespace-nowrap rounded-2xl border border-blue-600 border-solid  max-md:px-5"
                    >
                      Later
                    </button>
                    <button
                      onClick={() => {
                        setShowModal(false);
                      }}
                      className="gap-2.5 self-stretch px-7 py-3.5 text-white rounded-2xl bg-[linear-gradient(90deg,#1D68FF_50%,#9A52DE_97.52%)]  max-md:px-5"
                    >
                      Get Started
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-30 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </div>
  );
}
export default Report;
const result = [
  {
    img: "/images/dashboard/survey/result.png",
    result: "Results",
    relationship: "Relationships",
    info: "Focused Rational and takes quick decisions",
  },
  {
    img: "/images/dashboard/survey/result.png",
    result: "Results",
    relationship: "Relationships",
    info: "Focused Rational and takes quick decisions",
  },
  {
    img: "/images/dashboard/survey/result.png",
    result: "Results",
    relationship: "Relationships",
    info: "Focused Rational and takes quick decisions",
  },
  {
    img: "/images/dashboard/survey/result.png",
    result: "Results",
    relationship: "Relationships",
    info: "Focused Rational and takes quick decisions",
  },
];
