import React, { useEffect, useState } from "react";
import HeaderDash from "./components/HeaderDash";
import MicrocredentialsData from "./components/MicrocredentialsData";
import { IoIosSearch } from "react-icons/io";
import CourceDescription from "./components/CourceDescription";
import CommentSection from "./components/CommentSection";


const Microcredentials = ({setActiveTab}) => {
  const [courceDesc, setCourceDesc] = useState(false);
  return (
    <main className="p-4 ">
      <HeaderDash setActiveTab={setActiveTab} />
      {courceDesc ? (
        <CourceDescription returnMicrocredentials={setCourceDesc} />
      ) : (
        <div className="mt-8 max-md:mr-2.5 max-lg:max-w-full">
          <div className="flex gap-5 max-lg:flex-col">
            <div className="flex flex-col w-[67%] max-md:ml-0 max-lg:w-full">
              <div className="flex flex-col mt-1.5 w-full max-md:mt-8 max-md:max-w-full">
                <div className="flex flex-wrap items-start self-start ml-6 text-2xl font-semibold leading-none text-green-600">
                  <img
                    loading="lazy"
                    src="/images/dashboard/quote-right.png"
                    className="object-contain shrink-0 self-start -mt-3  aspect-square w-[25px]"
                  />
                  <div className="flex-auto self-start max-md:max-w-full">
                    Dream big, strive for greatness!
                  </div>
                  <img
                    loading="lazy"
                    src="/images/dashboard/quote-left.png"
                    className="object-contain shrink-0 self-end mt-4 aspect-square w-[25px]"
                  />
                </div>

                <div className="flex flex-col px-5 py-7 mt-3 w-full bg-white rounded-xl shadow-2xl max-md:px-5 max-md:max-w-full">
                  <div className="flex flex-wrap gap-10 justify-between max-w-full">
                    <div className="text-2xl font-bebas tracking-[1px] md:my-auto text-slate-950">
                      MICROCREDENTIALS
                    </div>
                    <div className="flex gap-2  px-3 py-2 text-sm leading-relaxed text-black bg-white border-[#E8E8E8] border-[1px] rounded-xl ">
                      <IoIosSearch className="my-auto text-2xl text-gray-400 " />
                      <input
                        type="search"
                        name="search-form"
                        id="search-form"
                        className=" outline-none border-none my-auto"
                        placeholder="Search course"
                      />
                    </div>
                  </div>
                  <div
                    style={{ scrollbarWidth: "none" }}
                    className="mt-5 h-screen overflow-y-auto"
                  >
                    {MicrocredentialsData.map((item) => {
                      return (
                        <div
                          onClick={() => setCourceDesc(true)}
                          className="flex flex-wrap gap-5 mt-3  border-[1px] pl-1 pr-2 cursor-pointer rounded-lg"
                        >
                          <div className="flex justify-between w-full max-md:flex-wrap gap-x-16 gap-y-5 items-center bg-white max-md:max-w-full">
                            <div className="flex gap-4 text-black">
                              <img
                                loading="lazy"
                                src={item.img}
                                className=" h-[55px] md:my-auto max-md:mt-1 w-[55px]"
                              />
                              <div className="flex flex-col max-md:max-w-full">
                                <div className="self-start text-base font-medium">
                                  {item.name}
                                </div>
                                <div className="text-sm max-md:max-w-full">
                                  {item.info}
                                </div>
                              </div>
                            </div>
                            <div className="flex gap-3 whitespace-nowrap  my-auto text-sm text-neutral-700">
                              <div className="text-lg text-blue-500">
                                {" "}
                                {item.logo}
                              </div>
                              <div>{item.enrolled}</div>
                            </div>
                            <div className="">{item.next}</div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-col w-[33%]  max-md:ml-0  max-lg:w-full">
            <CommentSection/>
            </div>
          </div>
        </div>
      )}
    </main>
  );
};

export default Microcredentials;

const CommentPeople = [
  {
    img: "/images/dashboard/user4.png",
    name: "Signe Frank",
    time: "10 Min ago",
    description:
      " The good news about dating your best friend is that you can simply enjoy the excitement of taking.",
  },
  {
    img: "/images/dashboard/user3.png",
    name: "Signe Frank",
    time: "10 Min ago",
    description:
      " The good news about dating your best friend is that you can simply enjoy the excitement of taking.",
  },
];
