import React, { useState } from "react";
import { IoIosSearch } from "react-icons/io";
import HeaderDash from "../HeaderDash";
import ClgOpFilter from "./careerPlanComponents/ClgOpFilter";
import ClgQuiz from "./careerPlanComponents/ClgQuiz";
import CalculateClgCost from "./careerPlanComponents/CalculateClgCost";

const ClgOption = ({setActiveTab}) => {
  const [takeQuiz, setTakeQuiz] = useState(false);
  const [clgCostClaculate, setclgCostClaculate] = useState(false);
  const [sortCollege, setSortCollge] = useState(false);
  const [isOpen, setIsOpen] = React.useState(false);

  const SortClg = () => {
    setclgCostClaculate(false);
    setTakeQuiz(false);
    setSortCollge(true);
  };
  return (
    <div className="flex flex-col p-4 w-full rounded-3xl bg-zinc-50 max-md:px-5 max-md:max-w-full">
      <HeaderDash setActiveTab={setActiveTab} />
      {takeQuiz === true ? (
        <ClgQuiz SortClg={SortClg} />
      ) : clgCostClaculate === true ? (
        <CalculateClgCost SortClg={SortClg} />
      ) : (
        <div>
          <div className="flex flex-wrap gap-5 mt-7 h-fit justify-between max-md:flex-col w-full  max-md:max-w-full">
            <div className="flex justify-between h-fit  pl-4 pr-1 py-1 md:w-[40%] text-sm text-black bg-white rounded-xl shadow-[0px_0px_19px_rgba(0,0,0,0.05)] max-md:max-w-full">
              <div className="flex gap-2 w-[80vw] ">
                <IoIosSearch className=" my-auto  text-2xl text-gray-400 " />
                <input
                  type="search"
                  name="search-form"
                  id="search-form"
                  className="w-full outline-none border-none my-auto "
                  placeholder="Search feed "
                />
              </div>
              <img
                onClick={() => setIsOpen(true)}
                loading="lazy"
                src="/images/dashboard/celebrate/filter.png"
                className="w-[45px] h-[45px] my-auto  cursor-pointer"
              />
            </div>
            <div
              style={{ scrollbarWidth: "none" }}
              className={`fixed top-0 right-0 h-full z-10 overflow-auto shadow-2xl  transition-transform duration-300 ease-in-out ${
                isOpen ? "translate-x-0" : "translate-x-full"
              }`}
            >
              <ClgOpFilter setIsOpen={setIsOpen} />
            </div>
            <div className="flex flex-wrap  gap-5 text-base  max-md:mt-5 text-center max-md:max-w-full">
              <button
                onClick={() => setclgCostClaculate(true)}
                className="flex flex-auto text-[#1D68FF] justify-center items-center my-auto px-5 py-3 rounded-xl border border-blue-600 border-solid"
              >
                Calculate College Cost
              </button>
              <button
                onClick={() => setTakeQuiz(true)}
                style={{
                  background:
                    "linear-gradient(90deg, #1D68FF 0%, #9A52DE97 100%)",
                }}
                className="flex flex-auto gap-2.5 justify-center items-center px-7 py-3 text-white rounded-xl  max-md:px-5"
              >
                <div className=" my-auto">Take College Quiz</div>
              </button>
            </div>
          </div>
          {sortCollege === true ? (
            <div className="text-black font-bebas text-xl mt-10 -mb-5 ml-4 tracking-[1px]">Colleges based on your results</div>
          ) : (
            ""
          )}
          <div className="grid grid-cols-5 gap-3.5 my-10">
            {institute.map((item) => {
              return (
                <div className="flex flex-col pt-1.5 px-3 pb-3 bg-white rounded-2xl border border-solid border-zinc-100">
                  <img
                    loading="lazy"
                    src={item.img}
                    className="object-contain rounded-lg aspect-[1.88] w-full"
                  />
                  <div className="mt-3 text-[15px] font-medium text-neutral-700">
                    {item.name}
                  </div>
                  <div className="text-[13px] mt-1 tracking-normal leading-5 text-black ">
                    {item.desc}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
};

export default ClgOption;

const institute = [
  {
    img: "/images/dashboard/career-planning/clg1.png",
    name: "InterCoast Colleges",
    desc: "Lorem ipsum dolor sit amet consectetur. Mauris vestibulum id ut diam sed tellus urna ipsum. ",
  },
  {
    img: "/images/dashboard/career-planning/clg2.png",
    name: "The Fab School",
    desc: "Lorem ipsum dolor sit amet consectetur. Mauris vestibulum id ut diam sed tellus urna ipsum. ",
  },
  {
    img: "/images/dashboard/career-planning/clg3.png",
    name: "American Institute",
    desc: "Lorem ipsum dolor sit amet consectetur. Mauris vestibulum id ut diam sed tellus urna ipsum. ",
  },
  {
    img: "/images/dashboard/career-planning/clg1.png",
    name: "InterCoast Colleges",
    desc: "Lorem ipsum dolor sit amet consectetur. Mauris vestibulum id ut diam sed tellus urna ipsum. ",
  },
  {
    img: "/images/dashboard/career-planning/clg2.png",
    name: "The Fab School",
    desc: "Lorem ipsum dolor sit amet consectetur. Mauris vestibulum id ut diam sed tellus urna ipsum. ",
  },
  {
    img: "/images/dashboard/career-planning/clg3.png",
    name: "American Institute",
    desc: "Lorem ipsum dolor sit amet consectetur. Mauris vestibulum id ut diam sed tellus urna ipsum. ",
  },
  {
    img: "/images/dashboard/career-planning/clg1.png",
    name: "InterCoast Colleges",
    desc: "Lorem ipsum dolor sit amet consectetur. Mauris vestibulum id ut diam sed tellus urna ipsum. ",
  },
  {
    img: "/images/dashboard/career-planning/clg2.png",
    name: "The Fab School",
    desc: "Lorem ipsum dolor sit amet consectetur. Mauris vestibulum id ut diam sed tellus urna ipsum. ",
  },
  {
    img: "/images/dashboard/career-planning/clg3.png",
    name: "American Institute",
    desc: "Lorem ipsum dolor sit amet consectetur. Mauris vestibulum id ut diam sed tellus urna ipsum. ",
  },
  {
    img: "/images/dashboard/career-planning/clg1.png",
    name: "InterCoast Colleges",
    desc: "Lorem ipsum dolor sit amet consectetur. Mauris vestibulum id ut diam sed tellus urna ipsum. ",
  },
  {
    img: "/images/dashboard/career-planning/clg2.png",
    name: "The Fab School",
    desc: "Lorem ipsum dolor sit amet consectetur. Mauris vestibulum id ut diam sed tellus urna ipsum. ",
  },
  {
    img: "/images/dashboard/career-planning/clg3.png",
    name: "American Institute",
    desc: "Lorem ipsum dolor sit amet consectetur. Mauris vestibulum id ut diam sed tellus urna ipsum. ",
  },
  {
    img: "/images/dashboard/career-planning/clg1.png",
    name: "InterCoast Colleges",
    desc: "Lorem ipsum dolor sit amet consectetur. Mauris vestibulum id ut diam sed tellus urna ipsum. ",
  },
  {
    img: "/images/dashboard/career-planning/clg2.png",
    name: "The Fab School",
    desc: "Lorem ipsum dolor sit amet consectetur. Mauris vestibulum id ut diam sed tellus urna ipsum. ",
  },
];
