import * as React from "react";
import { MdAccessTime } from "react-icons/md";
import { FaRegUser } from "react-icons/fa6";
import { BsBarChart } from "react-icons/bs";

function CourceDescription({returnMicrocredentials}) {
  return (
    <div className="px-5 py-6 mt-6 bg-white rounded-xl shadow-2xl max-md:max-w-full">
      <div className="flex gap-5 max-lg:flex-col">
        <div className="flex flex-col w-[64%] max-md:ml-0 max-lg:w-full">
          <div className="flex flex-col grow text-[#009A39] max-md:mt-10 max-md:max-w-full">
            <div
              style={{
                backgroundImage:
                  "url(/images/dashboard/microcredentials/robatics.png)",
              }}
              className="bg-cover relative flex items-end text-white min-h-[180px] rounded-xl"
            >
           <div className="absolute top-4 left-4 cursor-pointer">
         <button onClick={()=>returnMicrocredentials(false)} className="text-4xl border-2 px-3 rounded-lg">
         &lt;
          </button> 
           </div>
              <div className="flex items-center flex-wrap gap-x-5 gap-y-3 justify-between p-4 w-full max-md:max-w-full max-md:mt-16">       
                <div className="flex gap-2 ">
                  <div className="grow my-auto text-lg max-md:text-base font-medium tracking-tight leading-none">
                    Robotics Course
                  </div>
                  <div className="flex gap-1.5 text-lg font-medium whitespace-nowrap">
                    <div className="grow">3.4</div>
                    <img
                      loading="lazy"
                      src="/images/dashboard/microcredentials/star.png"
                      className="object-contain shrink-0 my-auto w-5 aspect-square"
                    />
                  </div>
                </div>
                <div className="flex flex-wrap gap-5 text-[15px] font-medium">
                  <div className="flex gap-1.5">
                    <MdAccessTime className="text-[20px] my-auto" />
                    <div>6 Hours</div>
                  </div>
                  <div className="flex gap-2.5">
                    <FaRegUser className="text-[19px] my-auto" />
                    <div className="basis-auto ">10.5k Learners</div>
                  </div>
                  <div className="flex gap-2.5 self-stretch whitespace-nowrap">
                    <BsBarChart className="text-[20px] my-auto" />
                    <div>Beginner</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="self-start mt-7 text-3xl max-md:text-2xl font-bebas tracking-[1.96px]">
              Description
            </div>
            <div className="mt-2.5 mr-8 text-[15px] leading-6 text-[#524B6B] max-md:mr-2.5 max-md:max-w-full">
              Sed ut perspiciatis unde omnis iste natus error sit voluptatem
              accusantium doloremque laudantium, totam rem aperiam, eaque ipsa
              quae ab illo inventore veritatis et quasi architecto beatae vitae
              dicta sunt explicabo. Nemo enim ipsam voluptatem. Sed ut
              perspiciatis unde omnis iste natus error sit voluptatem
              accusantium doloremque laudantium, totam rem aperiam, eaque ipsa
              quae ab illo inventore veritatis et ipsa quae ab illo inventor
            </div>
            <div className="self-start mt-7 font-bebas text-3xl max-md:text-2xl tracking-[1.96px]">
              What will I learn?
            </div>
            <div className="text-[#524B6B] text-[15px] pl-5 mt-3  max-md:max-w-full">
              <ul className="list-disc list-outside leading-relaxed">
                <li>Sed ut perspiciatis unde omnis iste natus error sit.</li>
                <li>
                  Neque porro quisquam est, qui dolorem ipsum quia dolor sit
                  amet, consectetur & adipisci velit.
                </li>
                <li>
                  Neque porro quisquam est, qui dolorem ipsum quia dolor sit
                  amet, consectetur & adipisci velit.
                </li>
                <li>
                  Neque porro quisquam est, qui dolorem ipsum quia dolor sit
                  amet, consectetur & adipisci velit. ipsum quia dolor sit amet,
                  consectetur & adipisci velit.
                </li>
              </ul>
            </div>
          </div>
          <div className="flex justify-center">
            {" "}
            <button
              style={{
                background:
                  "linear-gradient(90deg, #1D68FF 0%, #9A52DE97 100%)",
              }}
              className="md:px-14 py-3.5 mt-5 w-fit text-base text-center text-white rounded-2xl max-md:px-5 max-md:max-w-full"
            >
              Start Learning
            </button>
          </div>
        </div>
        <div className="flex flex-col ml-5 w-[36%] max-md:ml-0 max-lg:w-full">
          <div className="flex flex-col mt-1 w-full max-md:mt-10 max-md:max-w-full">
            <div className="flex flex-col  max-md:max-w-full">
              <div className="self-start font-bebas text-3xl text-[#009A39] tracking-[1.96px]">
                Ratings and Reviews
              </div>
              <div className=" p-3 w-full rounded-xl bg-[#F8F8F8] max-lg:max-w-full">
                <div className="flex justify-between gap-5 max-md:flex-col">
                  <div className="flex flex-col w-[50%] max-md:w-full">
                    <div className="flex gap-2 my-auto text-neutral-700 max-md:mt-10">
                      <div className="text-3xl font-medium">3.4</div>
                      <div className="flex flex-col self-start mt-1 text-sm">
                        <img
                          loading="lazy"
                          src="/images/dashboard/microcredentials/stars.png"
                          className="w-32"
                        />
                        <div className="mt-1 text-[13px] max-md:mr-1.5">
                          Based on 3 reviews
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col w-[50%] max-md:w-full">
                    <div className="flex flex-col w-full max-md:mt-10">
                      <div className="flex gap-1 text-xs text-neutral-700">
                        <div>5 Star</div>
                        <div className="flex my-auto h-2 bg-yellow-400 rounded w-[100px]" />
                      </div>
                      <div className="flex gap-1 mt-2 text-xs text-neutral-700">
                        <div>4 Star</div>
                        <div className="flex my-auto h-2 bg-yellow-400 rounded w-[80px]" />
                      </div>
                      <div className="flex gap-1 mt-2 text-xs text-neutral-700">
                        <div>3 Star</div>
                        <div className="flex my-auto h-2 bg-yellow-400 rounded w-[60px]" />
                      </div>
                      <div className="flex gap-1 mt-2 text-xs text-neutral-700">
                        <div>2 Star</div>
                        <div className="flex my-auto h-2 bg-yellow-400 rounded w-[40px]" />
                      </div>
                      <div className="flex gap-1 mt-2 text-xs text-neutral-700">
                        <div> 1 Star</div>
                        <div className="flex my-auto h-2 bg-yellow-400 rounded w-[20px]" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-wrap gap-10 mt-6 max-w-full">
              <div className="flex flex-col flex-1">
                <div className="text-base font-medium text-gray-600">
                  Joseph Stanly
                </div>
                <div className="self-start text-sm text-neutral-400">
                  Jan 2021
                </div>
              </div>
              <img
                loading="lazy"
                src="/images/dashboard/microcredentials/stars.png"
                className="w-28 h-6"
              />
            </div>
            <div className="mt-3.5 text-[15px] text-gray-600 max-md:mr-2.5 max-md:max-w-full">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Non id
              neque sit aliquam nam molestie dignissim ac eget. Fames congue
              faucibus in fermentum proin. A accumsan, convallis elementum amet
              sed.
            </div>
            <div className="flex flex-wrap gap-10 mt-8 max-w-full">
              <div className="flex flex-col flex-1">
                <div className="text-base font-medium text-gray-600">
                  Lara Nicoliva
                </div>
                <div className="self-start text-sm text-neutral-400">
                  Jan 2021
                </div>
              </div>
              <img
                loading="lazy"
                src="/images/dashboard/microcredentials/stars.png"
                className="w-28 h-6"
              />
            </div>
            <div className="mt-3.5 text-[15px] text-gray-600 max-md:mr-2.5 max-md:max-w-full">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Non id
              neque sit aliquam nam molestie dignissim ac eget. Fames congue
              faucibus in fermentum proin. A accumsan, convallis elementum amet
              sed.{" "}
            </div>
            <div className="flex flex-wrap gap-5 justify-between mt-8 max-w-full">
              <div className="flex flex-col">
                <div className="text-base font-medium text-gray-600">
                  Anna Doe
                </div>
                <div className="self-start text-sm text-neutral-400">
                  Jan 2021
                </div>
              </div>
              <img
                loading="lazy"
                src="/images/dashboard/microcredentials/stars.png"
                className="w-28 h-6"
              />
            </div>
            <div className="mt-3.5 text-[15px] text-gray-600 max-md:mr-2.5 max-md:max-w-full">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Non id
              neque sit aliquam nam molestie dignissim ac eget. Fames congue
              faucibus in fermentum proin. A accumsan, convallis elementum amet
              sed.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default CourceDescription;
