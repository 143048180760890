import React, { useState } from "react";
import { FaArrowLeftLong } from "react-icons/fa6";
function CalculateClgCost({SortClg}) {
  return (
    <div className="flex flex-col items-center pt-7 pr-20 pb-40 pl-10 mt-6 w-full bg-white rounded-xl shadow-2xl max-md:px-5 max-md:pb-24 max-md:max-w-full">
      <div className="flex gap-7 self-start max-w-full w-[259px]">
        <div className="flex gap-4">
          <div
            style={{ boxShadow: "0px 20px 50px 0px rgba(0, 0, 0, 0.12)" }}
            className="bg-gray-100 h-9 w-9 flex items-center justify-center rounded-2xl my-auto shadow-2xl"
          >
            <FaArrowLeftLong className=" " />
          </div>
          <div className="text-2xl my-auto font-bebas">
            Calculate College Cost
          </div>
        </div>
      </div>
      <div className="w-[80%] mx-auto mt-10 ">
        <div>
          <label for="YouWantSchool">Are you married?</label>

          <select
            name="YouWantSchool"
            className="w-full mt-4 h-12 outline-none border-none rounded-lg indent-3 shadow-[0px_0px_19px_rgba(0,0,0,0.05)]"
          >
            <option value="North Calorina">No</option>
            <option value="India">Yes</option>
          </select>
        </div>
        <div className="mt-6">
          <div className="text-base text-black ">
            What is your annual adjusted gross income? (AGI)?
          </div>
          <input
            type="text"
            placeholder="$45000"
            className="w-full mt-3 h-12 outline-none border-none rounded-lg indent-3 shadow-[0px_0px_19px_rgba(0,0,0,0.05)]"
          />
        </div>
        <div className="mt-6">
          <div className="text-base text-black ">
            What is your spouse’s annual AGI?
          </div>
          <input
            type="text"
            placeholder="$45000"
            className="w-full mt-3 h-12 outline-none border-none rounded-lg indent-3 shadow-[0px_0px_19px_rgba(0,0,0,0.05)]"
          />
        </div>
        <div className="mt-6">
          <div className="text-base text-black ">
            What is your child’s annual AGI?
          </div>
          <input
            type="text"
            placeholder="$45000"
            className="w-full mt-3 h-12 outline-none border-none rounded-lg indent-3 shadow-[0px_0px_19px_rgba(0,0,0,0.05)]"
          />
        </div>
        <div className="mt-6">
          <div className="text-base text-black ">
            How much do you have in non-retirement bank and investment accounts?
          </div>
          <input
            type="text"
            placeholder="$45000"
            className="w-full mt-3 h-12 outline-none border-none rounded-lg indent-3 shadow-[0px_0px_19px_rgba(0,0,0,0.05)]"
          />
        </div>
        <div className="mt-6">
          <div className="text-base text-black ">
            How much do you, your spouse and your child have in 529 plans and
            Coverdell ESAs?
          </div>
          <input
            type="text"
            placeholder="$45000"
            className="w-full mt-3 h-12 outline-none border-none rounded-lg indent-3 shadow-[0px_0px_19px_rgba(0,0,0,0.05)]"
          />
        </div>
        <div className="mt-6">
          <label for="YouWantSchool">Where does your family reside?</label>

          <select
            name="YouWantSchool"
            className="w-full mt-4 h-12 outline-none border-none rounded-lg indent-3 shadow-[0px_0px_19px_rgba(0,0,0,0.05)]"
          >
            <option value="North Calorina">North Calorina</option>
            <option value="India">North USA</option>
          </select>
        </div>
        <div className="mt-6">
          <div className="text-base text-black ">
            How old will you be when your child enrolled in college?
          </div>
          <input
            type="text"
            placeholder="45"
            className="w-full mt-3 h-12 outline-none border-none rounded-lg indent-3 shadow-[0px_0px_19px_rgba(0,0,0,0.05)]"
          />
        </div>
        <div className="mt-6">
          <div className="text-base text-black ">
            How many members will you have in your household when your child is
            enrolled in college?
          </div>
          <input
            type="text"
            placeholder="4"
            className="w-full mt-3 h-12 outline-none border-none rounded-lg indent-3 shadow-[0px_0px_19px_rgba(0,0,0,0.05)]"
          />
        </div>
        <div className="flex justify-center mt-10">
          <button onClick={()=>SortClg(true)}
            style={{
              background: "linear-gradient(90deg, #1D68FF 0%, #9A52DE97 100%)",
            }}
            className="py-3 md:w-[70%] px-10 text-white rounded-xl"
          >
            Get Results
          </button>
        </div>
      </div>
    </div>
  );
}

export default CalculateClgCost;
