import React, { useState, useEffect, useRef } from "react";
import { GoHome, GoHomeFill } from "react-icons/go";
import { FaBars } from "react-icons/fa"; // Import the icon for the toggle button
import { useSelector } from "react-redux";

const DashboardWrapper = ({ activeTab, setActiveTab, children }) => {
  const [isSidebarOpen, setSidebarOpen] = useState(false);
  const [planning, setPlanning] = useState(false);
  const cPlan = useSelector((state) => state.careerPlanState.c_plan_answered);
  console.log("current state on dashboardWrapper ", cPlan);
  const planningRef = useRef(null);

  const toggleSidebar = () => {
    setSidebarOpen(!isSidebarOpen);
  };
  const handleSeven = () => {
    if (cPlan) {
      setPlanning(true);
    } else {
      setActiveTab(7);
    }
  };

  // Handle clicks outside the planning section
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (planningRef.current && !planningRef.current.contains(event.target)) {
        setPlanning(false);
      }
    };

    // Attach the event listener
    document.addEventListener("mousedown", handleClickOutside);

    // Cleanup the event listener on component unmount
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  return (
    <div
      style={{
        background: "linear-gradient(90deg, #1D68FF 0%, #9A52DE97 100%)",
      }}
      className="flex min-h-[100vh] relative min-w-[320px]"
    >
      {/* Sidebar for full screen */}
      <aside
        style={{ scrollbarWidth: "none" }}
        className="w-2/12 max-lg:hidden text-white  h-[100vh] overflow-y-scroll "
      >
        <div className="p-4 mx-auto">
          <div className="text-white">
            <img
              src="/future-capacity.png"
              alt="not found"
              className="w-32 mx-auto"
            />
          </div>
        </div>
        <nav className="mt-4 flex flex-col justify-between h-full font-medium ">
          <ul className="cursor-pointer md:pl-2">
            <li
              className={`p-4  flex ${
                activeTab === 1
                  ? "bg-white text-[#009A39] border-[#8d2ce998] rounded-[5px]  border-solid border-r-[4px] "
                  : "hover:bg-blue-800 rounded-[5px]"
              }`}
              onClick={() => setActiveTab(1)}
            >
              <div className="text-xl md:mr-2 max-md:mr-1 my-auto">
                {activeTab === 1 ? <GoHomeFill /> : <GoHome />}
              </div>
              Dashboard
            </li>
            <li
              className={`p-4  flex ${
                activeTab === 2
                  ? "bg-white text-[#009A39] border-[#8d2ce998] rounded-[5px]  border-solid border-r-[4px] "
                  : "hover:bg-blue-800 rounded-[5px]"
              }`}
              onClick={() => setActiveTab(2)}
            >
              <div className="text-xl md:mr-2 max-md:mr-1 my-auto">
                {activeTab === 2 ? <GoHomeFill /> : <GoHome />}
              </div>
              Survey
            </li>
            <li
              className={`p-4  flex ${
                activeTab === 3
                  ? "bg-white text-[#009A39] border-[#8d2ce998] rounded-[5px]  border-solid border-r-[4px]"
                  : "hover:bg-blue-800 rounded-[5px]"
              }`}
              onClick={() => setActiveTab(3)}
            >
              <div className="text-xl md:mr-2 max-md:mr-1 my-auto">
                {activeTab === 3 ? <GoHomeFill /> : <GoHome />}
              </div>
              Journey's Map
            </li>
            <li
              className={`p-4  flex flex-wrap ${
                activeTab === 4
                  ? "bg-white text-[#009A39] border-[#8d2ce998] rounded-[5px]  border-solid border-r-[4px]"
                  : "hover:bg-blue-800 rounded-[5px]"
              }`}
              onClick={() => setActiveTab(4)}
            >
              <div className="text-xl md:mr-2 max-md:mr-1 my-auto">
                {activeTab === 4 ? <GoHomeFill /> : <GoHome />}
              </div>
              Microcredentials
            </li>
            <li
              className={`p-4  flex ${
                activeTab === 5
                  ? "bg-white text-[#009A39] border-[#8d2ce998] rounded-[5px]  border-solid border-r-[4px]"
                  : "hover:bg-blue-800 rounded-[5px]"
              }`}
              onClick={() => setActiveTab(5)}
            >
              <div className="text-xl md:mr-2 max-md:mr-1 my-auto">
                {activeTab === 5 ? <GoHomeFill /> : <GoHome />}
              </div>
              Celebrate
            </li>
            <li
              className={`p-4  flex ${
                activeTab === 6
                  ? "bg-white text-[#009A39] border-[#8d2ce998] rounded-[5px]  border-solid border-r-[4px] "
                  : "hover:bg-blue-800 rounded-[5px]"
              }`}
              onClick={() => setActiveTab(6)}
            >
              <div className="text-xl md:mr-2 max-md:mr-1 my-auto">
                {activeTab === 6 ? <GoHomeFill /> : <GoHome />}
              </div>
              Mentors
            </li>
            <div className="relative">
              <li
                className={`p-4  flex  ${
                  activeTab === 7
                    ? "bg-white text-[#009A39] border-[#8d2ce998] rounded-[5px]  border-solid border-r-[4px]"
                    : "hover:bg-blue-800 rounded-[5px]"
                }`}
                onClick={() => handleSeven()}
              >
                <div className="text-xl md:mr-2 max-md:mr-1 my-auto">
                  {activeTab === 7 ? <GoHomeFill /> : <GoHome />}
                </div>
                Career Planning
              </li>
              {planning ? (
                <div ref={planningRef} className=" ">
                  <div className="p-3 m-1 self-end text-[15px] leading-snug text-black bg-white rounded-md border border-solid border-zinc-300">
                    <div onClick={() => setActiveTab(10) || setPlanning(false)}  className="flex gap-3">
                      <img
                        loading="lazy"
                        src="/images/dashboard/career-planning/cte.png"
                        className="object-contain shrink-0 aspect-square w-[20px]"
                      />
                      <div className="basis-auto">CTE Pathways</div>
                    </div>
                    <div onClick={() => setActiveTab(11)} className="flex gap-2 mt-3">
                      <img
                        loading="lazy"
                        src="/images/dashboard/career-planning/military.png"
                        className="object-contain shrink-0 aspect-square w-[20px]"
                      />
                      <div className="my-auto basis-auto">Military Options</div>
                    </div>
                    <div onClick={() => setActiveTab(12)} className="flex gap-2 mt-3 flex-wrap">
                      <img
                        loading="lazy"
                        src="/images/dashboard/career-planning/entrepreneurship.png"
                        className="object-contain shrink-0 aspect-square w-[20px]"
                      />
                      <div className="my-auto basis-auto">Entrepreneurship</div>
                    </div>
                    <div onClick={() => setActiveTab(13)} className="flex gap-2 mt-3">
                      <img
                        loading="lazy"
                        src="/images/dashboard/career-planning/clg-option.png"
                        className="object-contain shrink-0 aspect-square w-[20px]"
                      />
                      <div className="my-auto basis-auto">College Options</div>
                    </div>
                  </div>
                </div>
              ) : (
                <></>
              )}
            </div>

            <li
              className={`p-4 flex ${
                activeTab === 8
                  ? "bg-white text-[#009A39] border-[#8d2ce998] rounded-[5px]  border-solid border-r-[4px]"
                  : "hover:bg-blue-800 rounded-[5px]"
              }`}
              onClick={() => setActiveTab(8)}
            >
              <div className="text-xl md:mr-2 max-md:mr-1 my-auto">
                {activeTab === 8 ? <GoHomeFill /> : <GoHome />}
              </div>
              Subscriptions
            </li>
            <li
              className={`p-4  flex ${
                activeTab === 9
                  ? "bg-white text-[#009A39] border-[#8d2ce998] rounded-[5px]  border-solid border-r-[4px]"
                  : "hover:bg-blue-800 rounded-[5px]"
              }`}
              onClick={() => setActiveTab(9)}
            >
              <div className="text-xl md:mr-2 max-md:mr-1 my-auto">
                {activeTab === 9 ? <GoHomeFill /> : <GoHome />}
              </div>
              Rate Our App
            </li>
          </ul>
          <div className="">
            <img src="/student.png" className="w-[18vw] pl-2 flex " />
          </div>
        </nav>
      </aside>
      {/* Sidebar for small screen */}
      <aside
        style={{ scrollbarWidth: "none" }}
        className={`fixed top-0 left-0 h-full bg-[#1D68FF] text-white lg:hidden transform shadow-2xl ${
          isSidebarOpen ? "translate-x-0" : "-translate-x-full"
        } lg:translate-x-0 transition-transform duration-300 ease-in-out w-52 overflow-y-scroll overflow-x-hidden z-50`}
      >
        <div className="p-4 mx-auto">
          <div className="text-white">
            <img
              src="/future-capacity.png"
              alt="not found"
              className="w-24 mx-auto"
            />
          </div>
        </div>
        <nav className="mt-4 flex flex-col justify-between h-full  font-medium ">
          <ul className="cursor-pointer md:pl-2">
            <li
              className={`p-4  flex ${
                activeTab === 1
                  ? "bg-white text-[#009A39] border-[#8d2ce998] rounded-[5px]  border-solid border-r-[4px] "
                  : "hover:bg-blue-800 rounded-[5px]"
              }`}
              onClick={() => setActiveTab(1)}
            >
              <div className="text-xl md:mr-2 max-md:mr-1 my-auto">
                {activeTab === 1 ? <GoHomeFill /> : <GoHome />}
              </div>
              Dashboard
            </li>
            <li
              className={`p-4  flex ${
                activeTab === 2
                  ? "bg-white text-[#009A39] border-[#8d2ce998] rounded-[5px]  border-solid border-r-[4px] "
                  : "hover:bg-blue-800 rounded-[5px]"
              }`}
              onClick={() => setActiveTab(2)}
            >
              <div className="text-xl md:mr-2 max-md:mr-1 my-auto">
                {activeTab === 2 ? <GoHomeFill /> : <GoHome />}
              </div>
              Survey
            </li>
            <li
              className={`p-4  flex ${
                activeTab === 3
                  ? "bg-white text-[#009A39] border-[#8d2ce998] rounded-[5px]  border-solid border-r-[4px]"
                  : "hover:bg-blue-800 rounded-[5px]"
              }`}
              onClick={() => setActiveTab(3)}
            >
              <div className="text-xl md:mr-2 max-md:mr-1 my-auto">
                {activeTab === 3 ? <GoHomeFill /> : <GoHome />}
              </div>
              Journey's Map
            </li>
            <li
              className={`p-4  flex ${
                activeTab === 4
                  ? "bg-white text-[#009A39] border-[#8d2ce998] rounded-[5px]  border-solid border-r-[4px]"
                  : "hover:bg-blue-800 rounded-[5px]"
              }`}
              onClick={() => setActiveTab(4)}
            >
              <div className="text-xl md:mr-2 max-md:mr-1 my-auto">
                {activeTab === 4 ? <GoHomeFill /> : <GoHome />}
              </div>
              Microcredentials
            </li>
            <li
              className={`p-4  flex ${
                activeTab === 5
                  ? "bg-white text-[#009A39] border-[#8d2ce998] rounded-[5px]  border-solid border-r-[4px]"
                  : "hover:bg-blue-800 rounded-[5px]"
              }`}
              onClick={() => setActiveTab(5)}
            >
              <div className="text-xl md:mr-2 max-md:mr-1 my-auto">
                {activeTab === 5 ? <GoHomeFill /> : <GoHome />}
              </div>
              Celebrate
            </li>
            <li
              className={`p-4  flex ${
                activeTab === 6
                  ? "bg-white text-[#009A39] border-[#8d2ce998] rounded-[5px]  border-solid border-r-[4px] "
                  : "hover:bg-blue-800 rounded-[5px]"
              }`}
              onClick={() => setActiveTab(6)}
            >
              <div className="text-xl md:mr-2 max-md:mr-1 my-auto">
                {activeTab === 6 ? <GoHomeFill /> : <GoHome />}
              </div>
              Mentors
            </li>
            <li
              className={`p-4  flex  relative ${
                activeTab === 7
                  ? "bg-white text-[#009A39] border-[#8d2ce998] rounded-[5px]  border-solid border-r-[4px]"
                  : "hover:bg-blue-800 rounded-[5px]"
              }`}
              onClick={() => setActiveTab(7)}
            >
              <div className="text-xl md:mr-2 max-md:mr-1 my-auto">
                {activeTab === 7 ? <GoHomeFill /> : <GoHome />}
              </div>
              Career Planning
            </li>
            <li
              className={`p-4 flex ${
                activeTab === 8
                  ? "bg-white text-[#009A39] border-[#8d2ce998] rounded-[5px]  border-solid border-r-[4px]"
                  : "hover:bg-blue-800 rounded-[5px]"
              }`}
              onClick={() => setActiveTab(8)}
            >
              <div className="text-xl md:mr-2 max-md:mr-1 my-auto">
                {activeTab === 8 ? <GoHomeFill /> : <GoHome />}
              </div>
              Subscriptions
            </li>
            <li
              className={`p-4  flex ${
                activeTab === 9
                  ? "bg-white text-[#009A39] border-[#8d2ce998] rounded-[5px]  border-solid border-r-[4px]"
                  : "hover:bg-blue-800 rounded-[5px]"
              }`}
              onClick={() => setActiveTab(9)}
            >
              <div className="text-xl md:mr-2 max-md:mr-1 my-auto">
                {activeTab === 9 ? <GoHomeFill /> : <GoHome />}
              </div>
              Rate Our App
            </li>
          </ul>
        </nav>
      </aside>

      {/* Main Content */}
      <div className="relative lg:w-10/12  max-lg:w-[95vw] mx-auto min-w-[300px] ">
        <div className="flex lg:hidden justify-end p-4">
          <button
            className="p-2 text-white bg-blue-500 rounded-md"
            onClick={toggleSidebar}
          >
            <FaBars />
          </button>
        </div>
        <main
          style={{ scrollbarWidth: "none" }}
          className="flex-1 overflow-y-scroll overflow-x-hidden h-[88vh] bg-[#FCFCFC] my-10 max-lg:my-5 md:mr-6 rounded-xl "
        >
          {children}
        </main>

        {/* Footer */}
        {/* <footer className=" flex w-full px-44 justify-between">
          <p className=" text-sm text-white  items-end rounded-lg">
            Copyright © My Future Capacity
          </p>
          <div className="flex gap-5">
          <p className="  text-sm text-white  items-end rounded-lg">
            Privacy Policy
          </p>
          <p className="  text-sm text-white items-end rounded-lg">
            Terms & Condition
          </p>
          </div>
        </footer> */}
      </div>
    </div>
  );
};

export default DashboardWrapper;
