export const SHOWCASEDATA = [
  {
    id: 1,
    image: "/images/dashboard/profile/showcase4.png",
    desc: "One answer is that Truth pertains to the possibility that an event will occur. If true, congratulate Joe on his new blog!",
    profilePic: "/images/dashboard/profile/profile-pic.png",
    name: "abhi Jovanic",
  },
  {
    id: 2,
    image: "/images/dashboard/profile/showcase1.png",
    desc: "One answer is that Truth pertains to the possibility that an event will occur. If true, congratulate Joe on his new blog!",
    profilePic: "/images/dashboard/profile/profile-pic.png",
    name: "sahil Jovanic",
  },
  {
    id: 3,
    image: "/images/dashboard/profile/showcase2.png",
    desc: "One answer is that Truth pertains to the possibility that an event will occur. If true, congratulate Joe on his new blog!",
    profilePic: "/images/dashboard/profile/profile-pic.png",
    name: "scott Jovanic",
  },
  {
    id: 4,
    image: "/images/dashboard/profile/showcase3.png",
    desc: "One answer is that Truth pertains to the possibility that an event will occur. If true, congratulate Joe on his new blog!",
    profilePic: "/images/dashboard/profile/profile-pic.png",
    name: "max Jovanic",
  },
  {
    id: 5,
    image: "/images/dashboard/profile/showcase4.png",
    desc: "One answer is that Truth pertains to the possibility that an event will occur. If true, congratulate Joe on his new blog!",
    profilePic: "/images/dashboard/profile/profile-pic.png",
    name: "ankit Jovanic",
  },
  {
    id: 6,
    image: "/images/dashboard/profile/showcase2.png",
    desc: "One answer is that Truth pertains to the possibility that an event will occur. If true, congratulate Joe on his new blog!",
    profilePic: "/images/dashboard/profile/profile-pic.png",
    name: "rahul Jovanic",
  },
  {
    id: 7,
    image: "/images/dashboard/profile/showcase1.png",
    desc: "One answer is that Truth pertains to the possibility that an event will occur. If true, congratulate Joe on his new blog!",
    profilePic: "/images/dashboard/profile/profile-pic.png",
    name: "pallavi Jovanic",
  },
  {
    id: 8,
    image: "/images/dashboard/profile/showcase3.png",
    desc: "One answer is that Truth pertains to the possibility that an event will occur. If true, congratulate Joe on his new blog!",
    profilePic: "/images/dashboard/profile/profile-pic.png",
    name: "sachin Jovanic",
  },
  {
    id: 9,
    image: "/images/dashboard/profile/showcase4.png",
    desc: "One answer is that Truth pertains to the possibility that an event will occur. If true, congratulate Joe on his new blog!",
    profilePic: "/images/dashboard/profile/profile-pic.png",
    name: "Joe Jovanic",
  },

];
