import React, { useState } from "react";
import Box from "@mui/material/Box";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import { FaUser } from "react-icons/fa";
import { IoMdMail } from "react-icons/io";
import { FaSchool } from "react-icons/fa";
import { IoIosArrowDown } from "react-icons/io";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import IconButton from "@mui/material/IconButton";
import { useNavigate } from "react-router-dom";
import Slick1 from "../common/Slick1";

const Login = () => {
  const [showPassword, setShowPassword] = React.useState(false);
  const navigate = useNavigate();
  const goToSignup = () => {
    navigate("/signUp", {});
  };
  const goToForgot = () => {
    navigate("/forgot-password", {});
  };
  // form handling
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    schoolName: "",
    schoolDist: "",
    createPass: "",
    confirmPass: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission
    console.log(formData);
  };

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  return (
    <div
      style={{ backgroundImage: "url(/images/auth/auth-bg.png)" }}
      className="bg-fixed bg-top   bg-cover min-w-[320px] min-h-[100vh]"
    >
      <div className="flex lg:justify-between max-lg:px-16 max-md:px-7 py-5">
        <div className="flex flex-col lg:mx-[10%] lg:w-[30%] mt-[7%]  ">
          <img
            loading="lazy"
            src="/images/auth/mainLogo.png"
            className="self-center max-w-full aspect-[0.93] w-[100px]"
          />
          <div className="self-center font-bebas text-4xl max-md:text-3xl mt-2.5 text-green-600 tracking-[2.55px] ">
            WELCOME BACK!
          </div>

          <form onSubmit={handleSubmit}>
            <Box
              component="form"
              sx={{
                "& .MuiTextField-root": { marginTop: 1.5 },
                "& .MuiInput-underline:hover:before": {
                  borderBottom: "none", // No border bottom on hover
                },
                "& .MuiInputBase-input::placeholder": {
                  fontSize: "14px", // Adjust placeholder font size here
                },
                "& .MuiFormLabel-root": {
                  padding: "15px 3px 0px 21px", // Adjust padding as needed
                  fontSize: "17px",
                  color: "black",
                },
                "& .MuiInputBase-root": {
                  padding: "10px 0px 3px 15px", // Optional: Adjust input padding as needed
                },
                "& .MuiInput-underline:before": {
                  borderBottom: "none",
                },
                "& .MuiInput-underline:after": {
                  borderBottom: "none",
                },
                "& .MuiInput-underline:hover:before": {
                  borderBottom: "none",
                },
              }}
              noValidate
              autoComplete="off"
            >
              <TextField
                id="input-with-icon-textfield"
                label="Email"
                placeholder="johndoe@gmail.com"
                // value={formData.email}
                // onChange={handleChange}
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end" sx={{ margin: "0px 20px" }}>
                      <IoMdMail />
                    </InputAdornment>
                  ),
                }}
                variant="standard"
                className="bg-white w-full rounded-xl pt-10"
              />

              <TextField
                variant="standard"
                label="Create New Password"
                placeholder="***********"
                type={showPassword ? "text" : "password"}
                // value={formData.createPass}
                // onChange={handleChange}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end" sx={{ margin: "0px 8px" }}>
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                className="bg-white w-full rounded-xl pt-10"
              />
            </Box>
            <div className="flex gap-2 justify-between mt-4 max-w-full">
              <div className="flex my-auto">
                <input
                  type="checkbox"
                  className="w-5 h-5 mt-0.5 mr-1"
                  id=""
                  name=""
                  value=""
                />
                <div className="text-base flex justify-between  leading-6 ">
                  Remember Me?
                </div>
              </div>
              <button onClick={()=>goToForgot()} className="text-blue-600 cursor-pointer">
                {" "}
                Forgot Password?
              </button>
            </div>
            <button
              style={{
                background:
                  "linear-gradient(90deg, #1D68FF 0%, #9A52DE97 100%)",
              }}
              className="px-4 py-3.5 mt-5 w-full text-base text-center text-white rounded-2xl max-md:px-5 max-md:max-w-full"
            >
              Login
            </button>
            <div className="flex justify-center mt-7  text-base text-slate-950">
              Don’t have an account?
              <button onClick={()=>goToSignup()} className="font-medium text-blue-600 pl-1 cursor-pointer">
                Register
              </button>
            </div>
          </form>
        </div>
        <div className="max-lg:hidden flex items-end fixed right-0 bottom-0 w-[40%]">
          <Slick1 />
        </div>
      </div>
    </div>
  );
};

export default Login;
