import { createSlice } from "@reduxjs/toolkit"

export const initialState={
    c_plan_answered:false
}
export const c_plans_slice=createSlice({
    name:"cPlanning",
    initialState,
    reducers:{
        c_plan_update(state,action){
            state.c_plan_answered=action.payload
        }
    }
})
export const {c_plan_update}=c_plans_slice.actions;
export default c_plans_slice.reducer;