import React, { useState, useEffect } from "react";
import OTPInput from "react-otp-input";
import Slick1 from "./Slick1";
import { useLocation, useNavigate } from "react-router-dom";

function Otp() {
  const [otp, setOtp] = useState("");
  const [timeLeft, setTimeLeft] = useState(30);

  const navigate = useNavigate();
  const handleButtonClick = () => {
       if (buttonText === "Submit") {
      navigate("/subscriptions");
    } else if (buttonText === "Verify & Create Password") {
      navigate("/create-password");
    } else {
      navigate("/default");
    }
  };

  const location = useLocation();
  const { type, data } = location.state || {};
  const buttonText = data?.button || 'Submit';
  // Log the data to the console for debugging
  // console.log("Type:", type);
  // console.log("Data:", data);

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle OTP submission based on type
    if (type === "individual") {
      console.log("Handling individual OTP");
    } else if (type === "classroom") {
      console.log("Handling classroom OTP");
    } else if (type === "ForgotPassword") {
      console.log("Handling ForgotPassword OTP");
    }
  };

  useEffect(() => {
    if (timeLeft > 0) {
      const timer = setTimeout(() => setTimeLeft(timeLeft - 1), 1000);
      return () => clearTimeout(timer); // Cleanup the timer
    }
  }, [timeLeft]);

  return (
    <div
      style={{ backgroundImage: "url(/images/auth/auth-bg.png)" }}
      className="bg-fixed bg-top bg-cover min-w-[320px] min-h-[100vh]"
    >
      <div className="max-lg:px-16 max-md:px-7 py-5 w-full">
        <div className="flex flex-col justify-center lg:ml-[10%] mt-[5%] w-[32%] max-lg:w-full">
          <img
            loading="lazy"
            src="/images/auth/mainLogo.png"
            className="self-center max-w-full aspect-[0.93] w-[100px]"
          />
          <div className="self-center font-bebas tracking-[2.55px] mt-2.5 text-4xl max-md:text-3xl text-green-600 ">
            VERIFICATION CODE
          </div>
          <div className="mt-1.5 text-base text-center text-zinc-500 max-md:max-w-full">
            <span className="leading-5 text-zinc-500">
              Please, check your email. We’ve sent you
            </span>
            <br />
            <span className="leading-5 text-zinc-500">the code at</span>
            <span className="leading-5 text-slate-950"> johndoe@gmail.com</span>
          </div>
          <div>
            <div className="gap-3 mt-5 text-dark flex justify-center">
              <OTPInput
                value={otp}
                onChange={setOtp}
                autoFocus
                OTPLength={4}
                otpType="number"
                disabled={false}
                renderSeparator={<span></span>}
                renderInput={(props) => (
                  <input
                    {...props}
                    className="focus:border-[#1D68FF] border-2 "
                    style={{
                      height: "65px",
                      width: "65px",
                      textAlign: "center",
                      borderRadius: "10px",
                      margin: "0 5px",
                      outline: "none",
                      paddingBottom: "6px",
                      fontSize: "30px",
                    }}
                  />
                )}
              />
            </div>
          </div>
          <div className="text-center text-zinc-500 mt-5">
            Time left:{" "}
            {timeLeft > 0
              ? `00:${timeLeft < 10 ? `0${timeLeft}` : timeLeft}`
              : "Time's up!"}
          </div>
          <button onClick={handleButtonClick}
            style={{
              background: "linear-gradient(90deg, #1D68FF 0%, #9A52DE97 100%)",
            }}
            className="px-4 py-3.5 mt-5 w-full text-base text-center text-white rounded-2xl"
          >
            {buttonText}
          </button>
          <div className="flex justify-center mt-5  text-base text-slate-950">
            Didn’t receive a code?
            <span className="font-medium pl-1 text-blue-600 cursor-pointer">
              Resend Code
            </span>
          </div>
        </div>
        <div className="max-lg:hidden flex items-end fixed right-0 bottom-0 w-[40%]">
          <Slick1 />
        </div>
      </div>
    </div>
  );
}

export default Otp;
