import React, { useState } from 'react';

const ChatSection = () => {
  // State to manage the input comment, list of comments, and emoji list visibility
  const [inputComment, setInputComment] = useState('');
  const [comments, setComments] = useState(CommentPeople);
  const [showEmojis, setShowEmojis] = useState(false);

  // List of emojis
  const emojis = ['😊', '😂', '😍', '😢', '😠'];

  // Handle input change
  const handleInputChange = (e) => {
    setInputComment(e.target.value);
  };

  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    if (inputComment.trim()) {
      const newComment = {
        img: '/images/dashboard/user4.png', // Placeholder for user's profile image
        name: 'You', // Placeholder for user's name
        time: 'Just now',
        description: inputComment,
      };
      setComments([...comments, newComment]);
      setInputComment(''); // Clear input field
    }
  };

  // Handle emoji click
  const handleEmojiClick = (emoji) => {
    setInputComment(inputComment + emoji);
    setShowEmojis(false); // Hide emoji list after selection
  };

  return (
    <div className="flex flex-col items-start px-5 py-5 mx-auto w-full  bg-white rounded-3xl shadow-2xl max-md:px-3 max-md:mt-6 max-md:max-w-full">
      <div className="flex gap-5 leading-loose text-black max-md:ml-1.5">
        <img
          loading="lazy"
          src="/images/dashboard/user2.png"
          className="object-contain shrink-0 aspect-square rounded-[100px] w-[62px]"
        />
        <div className="flex flex-col my-auto">
          <div className="text-base font-medium">Caroline</div>
          <div className="text-xs max-md:mr-1.5 opacity-50">10 Min ago</div>
        </div>
      </div>
      <img
        loading="lazy"
        src="/images/dashboard/post.png"
        className="object-contain self-stretch mt-4 w-full aspect-[1.82] max-md:mr-1.5 max-md:max-w-full"
      />
      <div className="mt-2.5 text-sm leading-6 text-neutral-700 max-md:ml-1.5">
        Lorem Ipsum is simply dummy text of the printing and typesetting
        industry.
      </div>
      <div className="flex gap-5 mt-3 leading-none text-black whitespace-nowrap max-md:ml-1.5">
        <div className="flex gap-1.5 text-sm">
          <img
            loading="lazy"
            src="/images/dashboard/clappingHand.png"
            className="object-contain shrink-0 aspect-square w-6"
          />
          <div className="my-auto">83</div>
        </div>
        <div className="flex gap-1.5 text-sm">
          <img
            loading="lazy"
            src="/images/dashboard/comment.png"
            className="object-contain shrink-0 w-6 aspect-square"
          />
          <div className="my-auto">18</div>
        </div>
      </div>
      <div className="mt-5 text-sm font-medium leading-snug text-blue-600 max-md:ml-1.5">
        Comments
      </div>
      <div style={{scrollbarWidth:"none"}} className='max-h-[50vh] w-80 overflow-x-hidden flex-wrap overflow-y-auto'>
      {comments.map((item, index) => (
        <div key={index}>
          <div className="flex gap-4 mt-2.5 leading-loose text-black max-md:ml-1.5">
            <img
              loading="lazy"
              src={item.img}
              className="object-contain shrink-0 aspect-[1.02] rounded-[100px] w-[52px]"
            />
            <div className="flex flex-col my-auto">
              <div className="text-sm font-medium">{item.name}</div>
              <div className="self-start text-xs">{item.time}</div>
            </div>
          </div>
          <div className="self-stretch mt-1.5  mr-4 ml-3 text-sm leading-6 text-black max-md:mr-2.5 max-md:max-w-full">
            {item.description}
          </div>
        </div>
      ))}
      </div>
      <div className="flex flex-col gap-4 w-full ">
        <div className="flex gap-4 justify-between self-stretch px-2 max-md:px-1 py-4 mt-5 w-full min-w-full rounded-3xl bg-neutral-100 max-md:max-w-full">
          <form
            onSubmit={handleSubmit}
            className="flex gap-1 gap-y-2 justify-between flex-wrap text-[15px] max-md:text-sm text-black max-md:pr-1 w-full"
          >
            <div className='flex gap-1'>
            <button
              type="button"
              onClick={() => setShowEmojis(!showEmojis)}
              className="flex items-center justify-center aspect-square w-6"
            >
              <img
                loading="lazy"
                src="/images/dashboard/emoji.png"
                className="aspect-square w-5"
              />
            </button>
            <input
              type="text"
              value={inputComment}
              onChange={handleInputChange}
              placeholder="Type here...."
              className="outline-none border-none w-full bg-neutral-100 pl-1"
            />
            </div>
            <button type="submit" className="flex gap-3 my-auto ">
              <img
                loading="lazy"
                src="/images/dashboard/attach.png"
                className="aspect-square w-5 max-md:w-4"
              />
              <img
                loading="lazy"
                src="/images/dashboard/send.png"
                className="aspect-square w-5 max-md:w-4"
              />
            </button>
          </form>
        </div>
        {showEmojis && (
          <div className="flex gap-2 mt-2 mb-4">
            {emojis.map((emoji, index) => (
              <button
                key={index}
                type="button"
                onClick={() => handleEmojiClick(emoji)}
                className="text-xl"
              >
                {emoji}
              </button>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default ChatSection;

const CommentPeople = [
  {
    img: "/images/dashboard/user4.png",
    name: "Signe Frank",
    time: "10 Min ago",
    description:
      "The good news about dating your best friend is that you can simply enjoy the excitement of taking.",
  },
  {
    img: "/images/dashboard/user3.png",
    name: "Signe Frank",
    time: "10 Min ago",
    description:
      "The good news about dating your best friend is that you can simply enjoy the excitement of taking.",
  },
];
