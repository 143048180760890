import * as React from "react";
import { IoIosArrowUp } from "react-icons/io";

function Filter({setIsOpen}) {
  return (
    <div className="flex flex-col items-start p-10 bg-white min-h-screen">
      <div className="flex gap-5 justify-between self-stretch w-full max-md:max-w-full">
        <div className="my-auto text-2xl font-bebas tracking-widest text-neutral-700">
          FILTER
        </div>
        <img onClick={() => setIsOpen(false)}
          loading="lazy"
          src="/images/dashboard/celebrate/cross.png"
          className=" w-[34px] bg-gray-50 rounded-full cursor-pointer"
        />
      </div>
      <div className="mt-5 text-xl font-bebas tracking-widest text-green-600">
        Category
      </div>
      <div className="flex gap-5 justify-between self-stretch px-4 py-4 mt-2.5 text-[15px] text-black whitespace-nowrap bg-white rounded-xl shadow-[0px_0px_19px_rgba(0,0,0,0.05)]  max-md:max-w-full">
        <div className="">Design</div>
        <IoIosArrowUp className="my-auto" />
      </div>
      <div className="mt-5 text-xl font-bebas tracking-widest text-green-600">
        Sub Category
      </div>
      <div className="flex gap-5 justify-between self-stretch px-4 py-4 mt-2.5 text-[15px] text-black whitespace-nowrap bg-white rounded-xl shadow-[0px_0px_19px_rgba(0,0,0,0.05)]  max-md:max-w-full">
        <div className="">UX Design</div>
        <IoIosArrowUp className="my-auto" />
      </div>
      <div className="mt-8 text-xl font-bebas tracking-widest text-green-600 max-md:mt-10">
        Last update
      </div>
      <div className="flex gap-3 mt-4 text-[15px] text-gray-600 whitespace-nowrap max-md:ml-1">
      <input type="checkbox" class="w-4 h-4 my-auto bg-white border-2 border-gray-300 rounded-full appearance-none cursor-pointer checked:border-[#FF9228] checked:ring-1 checked:ring-[#FF9228]  checked:bg-[#FF9228] checked:ring-offset-2" />
      <div className="my-auto">Recent</div>
      </div>
      <div className="flex gap-3 mt-4 text-[15px] text-gray-600 whitespace-nowrap max-md:ml-1">
      <input type="checkbox" class="w-4 h-4 my-auto bg-white border-2 border-gray-300 rounded-full appearance-none cursor-pointer checked:border-[#FF9228] checked:ring-1 checked:ring-[#FF9228]  checked:bg-[#FF9228] checked:ring-offset-2" />
      <div className="my-auto">Last week</div>
      </div>  <div className="flex gap-3 mt-4 text-[15px] text-gray-600 whitespace-nowrap max-md:ml-1">
      <input type="checkbox" class="w-4 h-4 my-auto bg-white border-2 border-gray-300 rounded-full appearance-none cursor-pointer checked:border-[#FF9228] checked:ring-1 checked:ring-[#FF9228]  checked:bg-[#FF9228] checked:ring-offset-2" />
      <div className="my-auto">Last month</div>
      </div>  <div className="flex gap-3 mt-4 text-[15px] text-gray-600 whitespace-nowrap max-md:ml-1">
      <input type="checkbox" class="w-4 h-4 my-auto bg-white border-2 border-gray-300 rounded-full appearance-none cursor-pointer checked:border-[#FF9228] checked:ring-1 checked:ring-[#FF9228]  checked:bg-[#FF9228] checked:ring-offset-2" />
      <div className="my-auto">Any time</div>
      </div>

      <div className="flex flex-wrap gap-5 self-center mt-10 max-md:max-w-full">
        <button className="px-7 py-3 text-[15px] text-center whitespace-nowrap bg-white rounded-xl shadow-[0px_0px_19px_rgba(0,0,0,0.13)] text-neutral-400 max-md:px-5">
          Reset
        </button>
        <button
          style={{
            background: "linear-gradient(90deg, #1D68FF 0%, #9A52DE97 100%)",
          }}
          className="px-7 py-3 text-[15px]  tracking-wider text-white uppercase rounded-xl shadow-[0px_4px_62px_rgba(153,171,198,0.18)] w-fit max-md:px-5"
        >
          Apply Now
        </button>
      </div>
    </div>
  );
}
export default Filter;
