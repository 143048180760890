import * as React from "react";
import HeaderDash from "../HeaderDash";

function CtePathways({setActiveTab}) {
  return (
    <div className="p-4 min-w-[320px]">
      <HeaderDash setActiveTab={setActiveTab} />
      <div className="text-3xl pl-4 mt-12 font-bebas tracking-widest text-green-600 max-md:text-2xl ">
        Career TECHNICAL EDUCATION
      </div>
      <div className="grid grid-cols-3 max-md:grid-cols-2 max-sm:grid-cols-1 gap-4 mt-5 px-4">
        {careerTechnicalEducation.map((item) => {
          return (
            <div
              key={item.name}
              className={`relative flex flex-col p-4 w-full text-[#FFFFFF] min-h-[45vh] rounded-xl max-md:max-w-full ${item.bg}`}
            >
              <div className="text-lg font-semibold leading-snug">
                {item.name}
              </div>
              <div className="mt-3 text-base leading-7 pl-4">
                <ul className="list-disc">
                  {item.op.map((option, index) => (
                    <li key={index}>{option[`op${index + 1}`]}</li>
                  ))}
                </ul>
              </div>
              <div className="absolute bottom-0 right-0 w-28">
                <img src={item.img} alt={item.name} />
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
export default CtePathways;

const careerTechnicalEducation = [
  {
    name: "Agriculture & Natural Resources",
    bg: "bg-[#367943]",
    op: [
      { op1: "Agricultural Business" },
      { op2: "Agricultural Mechanics" },
      { op3: "Agriscience" },
      { op4: "Animal Science" },
      { op5: "Forestry and Natural Resources" },
      { op6: "Ornamental Horticulture" },
      { op7: "Plant and Soil Science" },
    ],
    img: "/images/dashboard/career-planning/agri.png",
  },
  {
    name: "Business & Finance",
    bg: "bg-[#38583D]",
    op: [
      { op1: "Business Management" },
      { op2: "Financial Services" },
      { op3: "International Business" },
    ],
    img: "/images/dashboard/career-planning/finance.png",
  },
  {
    name: "Public Services",
    bg: "bg-[#2A6663]",
    op: [
      { op1: "  Public Safety" },
      { op2: " Emergency Response" },
      { op3: "Legal Practices" },
    ],
    img: "/images/dashboard/career-planning/police.png",
  },
  {
    name: "Manufacturing & Product Development",
    bg: "bg-[#1C434C]",
    op: [
      { op1: "Graphic Production Technologies" },
      { op2: "Machining and Forming Technologies" },
      { op3: "Welding and Materials Joining" },
      { op4: " Product Innovation and Design" },
      
      
      
     
      
    ],
    img: "/images/dashboard/career-planning/development.png",
  },
  {
    name: "Health Science & Medical Technology",
    bg: "bg-[#2A5676]",
    op: [
      { op1: "Biotechnology" },
      { op2: "Patient Care" },
      { op3: "Health Care Administrative Services" },
      { op4: "Health Care Operational Support Services" },
      { op5: "Public and Community Health" },
      { op6: "Public and Community Health" },
      { op7: "Mental and Behavioral Health" },
      
    ],
    img: "/images/dashboard/career-planning/doctor.png",
  },
  {
    name: "Energy, Environmental and Utilities",
    bg: "bg-[#2C829C]",
    op: [
      { op1: "Environmental Recourses" },
      { op2: "Energy and Power Technology" },
      { op3: "Telecommunications" }, 
    ],
    img: "/images/dashboard/career-planning/plug.png",
  },
  {
    name: "Arts, Media & Entertainment",
    bg: "bg-[#4E2E77]",
    op: [
      { op1: "Design, Visual and Media Arts" },
      { op2: "  Performing Arts" },
      { op3: "Production and Managerial Arts" },
      { op4: "Game Design and Integration" },
      
    ],
    img: "/images/dashboard/career-planning/arts.png",
  },
  {
    name: "Marketing, Sales and Services",
    bg: "bg-[#5E0745]",
    op: [
      { op1: " Marketing" },
      { op2: "  Professional Sales" },
      { op3: "Entrepreneurship/Self-Employment" },
     
    
      
    ],
    img: "/images/dashboard/career-planning/speaker.png",
  },
  {
    name: "Hospitality, Tourism and Recreation",
    bg: "bg-[#8D2777]",
    op: [
      { op1: "   Child Development" },
      { op2: "Consumer Services" },
      { op3: "Education" },
      { op4: "Family and Human Services" },
   
      
      
      
      
    ],
    img: "/images/dashboard/career-planning/hospility.png",
  },
  {
    name: "Transportation",
    bg: "bg-[#CC7344]",
    op: [
      { op1: "Operations" },
      { op2: " Structural Repair and Refinishing" },
      { op3: "Systems Diagnostics, Service and Repair" },
      
     
      
    ],
    img: "/images/dashboard/career-planning/plane.png",
  },
  {
    name: "Fashion and Interior Design",
    bg: "bg-[#B64A4F]",
    op: [
      { op1: "Fashion Design and Merchandising" },
      { op2: "Interior Design" },
      { op3: "Personal Services" },
      
    ],
    img: "/images/dashboard/career-planning/chair.png",
  },
  {
    name: "Education, Child Development and Family Services",
    bg: "bg-[#B85288]",
    op: [
      { op1: "  Child Development" },
      { op2: "Consumer Services" },
      { op3: "Education" },
      { op4: "Family and Human Services" },
    
      
      
      
      
    ],
    img: "/images/dashboard/career-planning/education.png",
  },
  {
    name: "Building and Construction Trades",
    bg: "bg-[#CC874E]",
    op: [
      { op1: "  Cabinetry, Millwork and Woodworking" },
      { op2: "Engineering and Heavy Construction" },
      { op3: " Mechanicals Systems Installation and Repair" },
      { op4: "Residential & Commercial Construction" },
    
      
     
      
    ],
    img: "/images/dashboard/career-planning/building.png",
  },
  {
    name: "Engineering and Archiecture",
    bg: "bg-[#9F826C]",
    op: [
      { op1: "  Architectural Design" },
      { op2: "  Engineering Technology" },
      { op3: "Engineering Design" },
      { op4: "Environmental Engineering" },
    
    
      
      
    ],
    img: "/images/dashboard/career-planning/engineer.png",
  },
  {
    name: "Information and Communication Technologies",
    bg: "bg-[#ACB33C]",
    op: [
      { op1: " Information Support and Services" },
      { op2: "Networking" },
      { op3: "  Software and Systems Development" },
      { op4: "Games and Simulation" },
     
      
    
      
      
    ],
    img: "/images/dashboard/career-planning/computer.png",
  },
];
