import React, { useState } from "react";
import AddInterest from "./components/AddInterest";
import HeaderDash from "../components/HeaderDash";
import { FaPen } from "react-icons/fa";
import { FaUser } from "react-icons/fa6";
import { FaSchool } from "react-icons/fa";
import { INTERESTDATA } from "./components/interestData";
import { SHOWCASEDATA } from "./components/showcaseData";
import AddShowcase from "./components/AddShowcase";

const MyProfile = ({ setActiveTab }) => {
  const [addInterest, setAddInterest] = useState(false);
  const [addShowcase, setAddShowcase] = useState(false);

  const [profileImage, setProfileImage] = useState(null);
  const [interests, setInterests] = useState([]);
  const [showcase, setShowcase] = useState([]);

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const imageUrl = URL.createObjectURL(file);
      setProfileImage(imageUrl);
    }
  };
  const handleSaveInterests = (selectedItems) => {
    // Fetch and update the interests based on selectedItems
    setInterests(selectedItems);
  };
  const handleSaveShowcase = (selectedItems) => {
    // Fetch and update the showcase based on selectedItems
    setShowcase(selectedItems);
  };
  return (
    <div className="p-4">
      <HeaderDash setActiveTab={setActiveTab} />
      {addInterest ? (
        <AddInterest
          setInterest={setAddInterest}
          onSave={handleSaveInterests}
        />
      ) : addShowcase ? (
        <AddShowcase
          setAddShowcase={setAddShowcase}
          onSave={handleSaveShowcase}
        />
      ) : (
        <div className="mt-10">
          <div className="flex justify-between">
            <div className="flex flex-wrap w-fit items-start self-start ml-6 text-2xl font-semibold leading-none text-green-600">
              <img
                loading="lazy"
                src="/images/dashboard/quote-right.png"
                className="object-contain shrink-0 self-start -mt-3 mr-[1px]  aspect-square w-[25px]"
              />
              <div className="flex-auto self-start max-md:max-w-full">
                Dream big, strive for greatness!
              </div>
              <img
                loading="lazy"
                src="/images/dashboard/quote-left.png"
                className="object-contain shrink-0 self-end mt-4 aspect-square w-[25px]"
              />
            </div>
            <div>
              <button>aa</button>
            </div>
          </div>
          <div className="mt-9 max-md:mr-1 max-md:max-w-full">
            <div className="flex gap-5 max-md:flex-col">
              <div className="flex flex-col w-6/12  max-md:w-full">
                <div className="flex flex-col grow max-md:mt-5 max-md:max-w-full">
                  <div
                    style={{
                      boxShadow: "0px 4px 44px 0px rgba(0, 0, 0, 0.06)",
                    }}
                    className="flex flex-col px-7 py-5 w-full bg-white rounded-xl  max-md:px-5 max-md:mr-0.5 max-md:max-w-full"
                  >
                    <div className="max-md:max-w-full">
                      <div className="flex gap-x-10 gap-y-5 max-md:flex-col">
                        <div className="flex flex-col w-[22%] max-md:ml-0 max-md:w-full">
                          <div className="flex flex-col ">
                            <div className="flex flex-col items-center justify-center">
                              <div className="flex flex-col items-center justify-center">
                                <div className="relative w-28 h-28 rounded-full border-4 border-gray-300 max-md:w-24 max-md:h-24">
                                  {profileImage ? (
                                    <img
                                      src={profileImage}
                                      alt="Profile"
                                      className="object-cover w-full h-full rounded-full"
                                    />
                                  ) : (
                                    <div className="flex items-center justify-center w-full h-full bg-gray-100 rounded-full">
                                      <FaUser className="text-gray-400 text-3xl" />
                                    </div>
                                  )}
                                  <label
                                    htmlFor="profileImageInput"
                                    className="absolute bottom-0 right-0 transform  translate-y-1 z-20 bg-white border-blue-500 border-2 text-blue-500 p-1 rounded-full cursor-pointer"
                                  >
                                    <FaPen className="text-[15px]" />
                                  </label>
                                </div>
                                <input
                                  type="file"
                                  id="profileImageInput"
                                  className="hidden"
                                  accept="image/*"
                                  onChange={handleImageChange}
                                />
                              </div>

                              <input
                                type="file"
                                id="profileImageInput"
                                className="hidden"
                                accept="image/*"
                                onChange={handleImageChange}
                              />
                              <button
                                style={{
                                  background:
                                    "linear-gradient(90deg, #F79E1B 50%, #9A52DE 97.52%)",
                                }}
                                className="mt-3 rounded-2xl text-white text-[14px] w-fit px-3 py-1"
                              >
                                Add Batch
                              </button>
                            </div>
                          </div>
                        </div>
                        <div className="flex flex-col w-[78%] max-md:ml-0 max-md:w-full">
                          <div className="flex flex-col items-start mt-1 w-full max-md:mt-10 max-md:max-w-full">
                            <div className="flex  gap-5 justify-between w-full max-md:max-w-full">
                              <div className="flex flex-wrap gap-1.5">
                                <div className="text-2xl mt-[1px] font-bebas text-green-600">
                                  JOHN DOE
                                </div>
                                <div className=" text-sm text-purple-500 basis-auto">
                                  (ID: AWS3514612)
                                </div>
                              </div>
                              <div className=" border-blue-500 border-2 w-6 h-6 text-blue-500 p-1 rounded-full cursor-pointer">
                                <FaPen className="text-[12px] " />
                              </div>
                            </div>
                            <div className="flex gap-2.5 mt-1.5 text-sm text-slate-950">
                              <FaSchool className="text-[19px]" />
                              <div className="basis-auto">High School Name</div>
                            </div>
                            <div className="text-xs tracking-wide text-slate-950 text-opacity-40">
                              High School District Name
                            </div>
                            <div className="shrink-0 mt-3 h-px border border-solid border-neutral-400 w-[47px]" />
                            <div className="flex gap-1.5 mt-4 text-xl text-green-600">
                              <div className="font-bebas">ABOUT ME</div>
                              <div className="self-start mt-4 border-amber-500 border-solid border-b-[3px] w-[29px]" />
                            </div>
                            <div className="mt-1 text-sm font-medium text-slate-950">
                              Student tagline will go here
                            </div>
                            <div className="flex-auto text-sm leading-5 text-neutral-400 max-md:max-w-full">
                              Lorem ipsum dolor sit amet consectetur. Nunc ut eu
                              a est viverra sodales cursus elementum. Nibh sed
                              ipsum maecenas cursus tempor.
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    style={{
                      boxShadow: "0px 4px 44px 0px rgba(0, 0, 0, 0.06)",
                    }}
                    className="flex flex-col items-start px-5 py-11 mt-5 w-full bg-white rounded-xl max-md:max-w-full"
                  >
                    <div className="flex justify-between w-full">
                      <div className="flex gap-1.5 text-2xl text-green-600">
                        <div className="font-bebas">CAREER INTEREST</div>
                        <div className="self-start mt-5 border-amber-500 border-solid border-b-[3px] w-[29px]" />
                      </div>
                      {interests.length > 0 ? (
                        <div
                          onClick={() => setAddInterest(true)}
                          className="max-md:mt-1 border-blue-500 border-2 w-6 h-6 text-blue-500 p-1 rounded-full cursor-pointer"
                        >
                          <FaPen className="text-[12px] " />
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    {interests.length > 0 ? (
                      ""
                    ) : (
                      <div className="text-sm leading-loose text-neutral-400">
                       Add your interests that will be highlighted in your profile.
                      </div>
                    )}
                    <div>
                      <div className="mt-4 gap-4 grid grid-cols-4 max-sm:grid-cols-2 w-full">
                        {interests.length > 0 ? (
                          interests.map((itemId) => {
                            const item = INTERESTDATA.find(
                              (data) => data.id === itemId
                            );
                            return (
                              item && (
                                <div
                                  key={item.id}
                                  style={{
                                    background: item.bg,
                                    position: "relative",
                                  }}
                                  className="flex flex-col justify-center w-20 h-20 items-center rounded-xl cursor-pointer "
                                >
                                  <img
                                    src={item.image}
                                    alt={item.name}
                                    className=" max-w-12 max-md:max-w-10"
                                  />
                                  <div className="text-[#fff] mt-1 text-xs">
                                    {item.name}
                                  </div>
                                </div>
                              )
                            );
                          })
                        ) : (
                          <button
                            onClick={() => setAddInterest(true)}
                            className="mt-4 flex justify-center items-center text-[#C1C1C1] text-3xl border-dashed border-[#C1C1C1] rounded-xl border-[1px] w-20 h-20 max-md:mt-5"
                          >
                            +
                          </button>
                        )}
                      </div>
                    </div>
                    <div className="flex justify-between mt-16 max-md:mt-10 w-full">
                      <div className="flex gap-1.5  text-2xl text-green-600 whitespace-nowrap ">
                        <div className="font-bebas">SHOWCASE</div>
                        <div className="self-start mt-5 border-amber-500 border-solid border-b-[3px] w-[29px]" />
                      </div>
                      {showcase.length > 0 ? (
                        <div
                          onClick={() => setAddShowcase(true)}
                          className="max-md:mt-1 border-blue-500 border-2 w-6 h-6 text-blue-500 p-1 rounded-full cursor-pointer"
                        >
                          <FaPen className="text-[12px] " />
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    {showcase.length > 0 ? (
                      ""
                    ) : (
                      <div className="text-sm leading-loose text-neutral-400">
                        Add your showcase that will be highlighted in your
                        profile.
                      </div>
                    )}

                    <div className="w-full">
                      <div className="mt-4 flex flex-col gap-2 w-full">
                        {showcase.length > 0
                          ? showcase.map((itemId) => {
                              const item = SHOWCASEDATA.find(
                                (data) => data.id === itemId
                              );

                              return (
                                item && (
                                  <div
                                    style={{
                                      boxShadow:
                                        "0px 0px 19px 0px rgba(0, 0, 0, 0.12)",
                                    }}
                                    className="relative flex flex-wrap gap-3 p-2.5 max-w-full text-sm bg-white rounded-2xl w-full"
                                  >
                                    <div className="flex gap-3 max-md:flex-col">
                                      <img
                                        loading="lazy"
                                        src={item.image}
                                        className="object-contain self-start shrink-0 w-20 rounded-2xl aspect-square"
                                      />
                                      <div className="flex flex-col grow shrink-0 self-start basis-0 w-fit max-md:max-w-full">
                                        <div className="tracking-normal  text-black max-md:max-w-full">
                                          {item.desc}
                                        </div>
                                        <div className="flex gap-1.5 self-start mt-2 font-medium text-purple-600">
                                          <img
                                            loading="lazy"
                                            src={item.profilePic}
                                            className="object-contain shrink-0 aspect-square rounded-full overflow-hidden w-[25px]"
                                          />
                                          <div className="my-auto">
                                            {item.name}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                )
                              );
                            })
                          : ""}
                      </div>
                      {showcase.length > 0 ? (
                        ""
                      ) : (
                        <button
                          onClick={() => setAddShowcase(true)}
                          className="mt-4 flex justify-center items-center text-[#C1C1C1] text-3xl border-dashed border-[#C1C1C1] rounded-xl border-[1px] w-full h-20"
                        >
                          +
                        </button>
                      )}
                    </div>
                    <div className="flex gap-1.5 mt-16 text-2xl text-green-600 whitespace-nowrap max-md:mt-10">
                      <div className="font-bebas">achievements</div>
                      <div className="self-start mt-5 border-amber-500 border-solid border-b-[3px] w-[29px]" />
                    </div>
                    <div className="text-sm leading-loose text-neutral-400">
                      Add your achievements that will be highlighted in your
                      profile.
                    </div>
                    <div className="mt-7 flex justify-center items-center text-[#C1C1C1] text-3xl border-dashed border-[#C1C1C1] rounded-xl border-[1px] w-full h-20">
                      +
                    </div>
                  </div>
                </div>
              </div>
              <div
                style={{ boxShadow: "0px 4px 44px 0px rgba(0, 0, 0, 0.06)" }}
                className="flex flex-col bg-white rounded-xl w-6/12 max-md:w-full"
              >
                <div className="flex flex-col items-start pt-7 pr-14 pl-5 mx-auto w-full text-2xl text-green-600 bg-white rounded-xl  max-md:pr-5 max-md:mt-5 max-md:max-w-full">
                  <div className="flex gap-1.5">
                    <div className="font-bebas">Behavioral Superpower </div>
                    <div className="self-start mt-5 border-amber-500 border-solid border-b-[3px] w-[29px]" />
                  </div>
                  <div className="text-sm leading-loose text-neutral-400 max-md:max-w-full">
                    Your behavioral superpowers will be added here after you
                    take the{" "}
                    <span className="font-medium text-blue-600">
                      assessment
                    </span>
                    .
                  </div>
                  <img
                    loading="lazy"
                    srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/83d98f1ec868f814dbba7b91d2012d767cb26369dbed51d43b75bc3a6efb902d?placeholderIfAbsent=true&apiKey=2bd08978f0414da1aeb60acc3281d4ff&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/83d98f1ec868f814dbba7b91d2012d767cb26369dbed51d43b75bc3a6efb902d?placeholderIfAbsent=true&apiKey=2bd08978f0414da1aeb60acc3281d4ff&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/83d98f1ec868f814dbba7b91d2012d767cb26369dbed51d43b75bc3a6efb902d?placeholderIfAbsent=true&apiKey=2bd08978f0414da1aeb60acc3281d4ff&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/83d98f1ec868f814dbba7b91d2012d767cb26369dbed51d43b75bc3a6efb902d?placeholderIfAbsent=true&apiKey=2bd08978f0414da1aeb60acc3281d4ff&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/83d98f1ec868f814dbba7b91d2012d767cb26369dbed51d43b75bc3a6efb902d?placeholderIfAbsent=true&apiKey=2bd08978f0414da1aeb60acc3281d4ff&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/83d98f1ec868f814dbba7b91d2012d767cb26369dbed51d43b75bc3a6efb902d?placeholderIfAbsent=true&apiKey=2bd08978f0414da1aeb60acc3281d4ff&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/83d98f1ec868f814dbba7b91d2012d767cb26369dbed51d43b75bc3a6efb902d?placeholderIfAbsent=true&apiKey=2bd08978f0414da1aeb60acc3281d4ff&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/83d98f1ec868f814dbba7b91d2012d767cb26369dbed51d43b75bc3a6efb902d?placeholderIfAbsent=true&apiKey=2bd08978f0414da1aeb60acc3281d4ff"
                    className="object-contain mt-3.5 max-w-full aspect-[1.31] w-[272px]"
                  />
                  <div className="flex gap-1.5 mt-9">
                    <div className="font-bebas">
                      Industries BASED ON SUPERPOWERS
                    </div>
                    <div className="self-start mt-5 border-amber-500 border-solid border-b-[3px] w-[29px]" />
                  </div>
                  <div className="self-stretch mt-1 text-sm leading-loose text-neutral-400 max-md:max-w-full">
                    Your internships based on superpowers will be added here
                    after you take the{" "}
                    <span className="font-medium text-blue-600">
                      assessment.{" "}
                    </span>
                  </div>
                  <div className="flex gap-1.5 mt-24 whitespace-nowrap max-md:mt-10">
                    <div className="font-bebas">MICROCREDENTIALS</div>
                    <div className="self-start mt-5 border-amber-500 border-solid border-b-[3px] w-[29px]" />
                  </div>
                  <div className="self-stretch mt-1 text-sm leading-loose text-neutral-400 max-md:mr-1 max-md:max-w-full">
                    Your microcredentials will be added here after you complete
                    the test. Go to
                    <span className="font-medium text-blue-600">
                      {" "}
                      Microcredentials.
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            style={{ boxShadow: "0px 4px 44px 0px rgba(0, 0, 0, 0.06)" }}
            className="flex flex-col px-6 py-6 mt-5 w-full bg-white rounded-xl shadow-2xl max-md:px-5 max-md:mr-1 max-md:max-w-full"
          >
            <div className="flex gap-1.5 self-start text-2xl text-green-600 whitespace-nowrap">
              <div className="font-bebas">achievements</div>
              <div className="self-start mt-5 border-amber-500 border-solid border-b-[3px] w-[29px]" />
            </div>
            <div className="mt-7 flex justify-center items-center text-[#C1C1C1] text-3xl border-dashed border-[#C1C1C1] rounded-xl border-[1px] w-full h-20">
              +
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default MyProfile;
