import React, { useState } from "react";
import { FaArrowLeftLong } from "react-icons/fa6";
function ClgQuiz({SortClg}) {
  const [selectedTest, setSelectedTest] = useState("SAT");
  return (
    <div className="flex flex-col items-center pt-7 pr-20 pb-40 pl-10 mt-6 w-full bg-white rounded-xl shadow-2xl max-md:px-5 max-md:pb-24 max-md:max-w-full">
      <div className="flex gap-7 self-start max-w-full w-[259px]">
        <div className="flex gap-4">
          <div
            style={{ boxShadow: "0px 20px 50px 0px rgba(0, 0, 0, 0.12)" }}
            className="bg-gray-100 h-9 w-9 flex items-center justify-center rounded-2xl my-auto shadow-2xl"
          >
            <FaArrowLeftLong className=" " />
          </div>
          <div className="text-2xl my-auto font-bebas">Take college quiz</div>
        </div>
      </div>
      <div className="w-[80%] mx-auto mt-10 ">
        <label for="YouWantSchool">Where do you want to go to school?</label>

        <select
          name="YouWantSchool"
          className="w-full mt-4 h-12 outline-none border-none rounded-lg indent-3 shadow-[0px_0px_19px_rgba(0,0,0,0.05)]"
        >
          <option value="North Calorina">North Calorina</option>
          <option value="India">India</option>
          <option value="USA">USA</option>
          <option value="North USA">North USA</option>
        </select>
        <div className="mt-8">
          <div className="text-base text-black ">
            What is your high school GPA?
          </div>
          <input
            type="text"
            placeholder="3"
            className="w-full mt-4 h-12 outline-none border-none rounded-lg indent-3 shadow-[0px_0px_19px_rgba(0,0,0,0.05)]"
          />
        </div>
        <div className="flex flex-col items-center justify-center mt-8">
          <div className="w-full">
            <h2 className="text-base text-black">
              Did you take the SAT or ACT?
            </h2>
            <div className="flex justify-start gap-4 max-md:flex-col mt-4 w-[80%] ">
              <button
                onClick={() => setSelectedTest("SAT")}
                className={`w-full flex items-center justify-start gap-4 py-2 px-3  border rounded-lg shadow-sm transition-colors ${
                  selectedTest === "SAT" ? "border-blue-500" : "border-gray-300"
                }`}
              >
                <img
                  src="/images/dashboard/career-planning/sat.png"
                  alt="SAT"
                  className="w-10"
                />
                <span className="text-xl font-medium">SAT</span>
              </button>
              <button
                onClick={() => setSelectedTest("ACT")}
                className={`w-full flex items-center justify-start gap-3 px-3 py-2 border rounded-lg shadow-sm transition-colors ${
                  selectedTest === "ACT" ? "border-blue-500" : "border-gray-300"
                }`}
              >
                <img
                  src="/images/dashboard/career-planning/act.png"
                  alt="SAT"
                  className="w-7"
                />
                <span className="text-xl font-medium">ACT</span>
              </button>
            </div>

            {/* SAT Score Breakdown */}
            <div className={`${selectedTest === "SAT" ? "block" : "hidden"}`}>
              <h2 className="text-base font-medium mt-4">
                What is your SAT score breakdown?
              </h2>
              <input
                type="text"
                placeholder="Math Score"
                className="w-full mt-4 h-12 outline-none border-none rounded-lg indent-3 shadow-[0px_0px_19px_rgba(0,0,0,0.05)]"
              />
              <input
                type="text"
                placeholder="Reading Score"
                className="w-full mt-3 h-12 outline-none border-none rounded-lg indent-3 shadow-[0px_0px_19px_rgba(0,0,0,0.05)]"
              />
            </div>

            {/* ACT Score Breakdown */}
            <div className={`${selectedTest === "ACT" ? "block" : "hidden"}`}>
              <h2 className="text-base font-medium mt-4">
                What is your ACT score breakdown?
              </h2>
              <input
                type="text"
                placeholder="Math Score"
                className="w-full mt-4 h-12 outline-none border-none rounded-lg indent-3 shadow-[0px_0px_19px_rgba(0,0,0,0.05)]"
              />
              <input
                type="text"
                placeholder="Reading Score"
                className="w-full mt-3 h-12 outline-none border-none rounded-lg indent-3 shadow-[0px_0px_19px_rgba(0,0,0,0.05)]"
              />
            </div>
            <div className="flex justify-center mt-10">
              <button onClick={()=>SortClg(true)}
                style={{
                  background:
                    "linear-gradient(90deg, #1D68FF 0%, #9A52DE97 100%)",
                }}
                className="py-3 md:w-[70%] px-10 text-white rounded-xl"
              >
                Get Results
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default ClgQuiz;
