import React, { useState } from "react";
import { IoMdSad } from "react-icons/io";
import { IoMdHappy } from "react-icons/io";
import Report from "./Report";
import ReportProcessingPlan from "./ReportProcessingPlan"; // Import your processing page component
import HeaderDash from "./HeaderDash";



const Test = ({ setActiveTab }) => {
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [answers, setAnswers] = useState({});
  const [showProcessing, setShowProcessing] = useState(false);
  const [showReport, setShowReport] = useState(false);

  const questionsPerPage = 3;
  const startIndex =
    Math.floor(currentQuestion / questionsPerPage) * questionsPerPage;
  const currentQuestions = questions.slice(
    startIndex,
    startIndex + questionsPerPage
  );

  const handleAnswer = (option) => {
    setAnswers({
      ...answers,
      [currentQuestion]: option,
    });
    if (currentQuestion < questions.length - 1) {
      setCurrentQuestion(currentQuestion + 1);
    }
  };

  const isLastQuestionSet = startIndex + questionsPerPage >= questions.length;

  const handleSubmit = () => {
    // Handle final submission here
    console.log("Answers:", answers);

    // Show processing page first
    setShowProcessing(true);

    // After 5 seconds, show the report
    setTimeout(() => {
      setShowProcessing(false);
      setShowReport(true);
    }, 3000); 
  };

  const currentQuestionNumber =
    startIndex +
    (currentQuestions.findIndex(
      (q) => q.title === questions[currentQuestion]?.title
    ) +
      1);
  const totalQuestions = questions.length;

  return (
    <div>
   
      {showProcessing ? (
        <ReportProcessingPlan /> 
        
      ) : showReport ? (
        <Report setActiveTab={setActiveTab} /> 
      ) : (
        <div>
        <div
          style={{ boxShadow: "0px 4px 44px 0px rgba(0, 0, 0, 0.06)" }}
          className="bg-white p-5 mt-4 rounded-lg"
        >
          <div className="text-2xl flex max-md:justify-start gap-2 font-semibold mb-4">
            <div> Discover Your Superpowers</div>
            <div>
              <img
                src="/images/dashboard/survey/hand.png"
                alt="not found"
                className="w-7 my-auto"
              />
            </div>
          </div>
          <div className="mb-4">
            <span className="text-[#1D68FF] font-medium">
              Question {currentQuestionNumber} of {totalQuestions}
            </span>
            <div className="bg-[#F3F4FE] h-2 rounded-full mt-1">
              <div
                className="bg-[linear-gradient(90deg,#1D68FF_50%,#9A52DE_97.52%)] h-2 rounded-full"
                style={{
                  width: `${
                    ((Math.floor(currentQuestion / questionsPerPage) + 1) /
                      Math.ceil(questions.length / questionsPerPage)) *
                    100
                  }%`,
                }}
              ></div>
            </div>
          </div>
          <div className="mb-6">
            {currentQuestions.map((q, index) => (
              <div key={index} className="mb-6">
                <div className="flex flex-wrap gap-5 justify-between mt-12 mr-7 w-full max-w-[1382px] max-md:mt-10 max-md:mr-2.5 max-md:max-w-full">
                  <div className="flex flex-col max-md:max-w-full">
                    <div className="self-start text-xl font-medium text-neutral-700">
                      {q.title}
                    </div>
                    <div className="text-base text-neutral-400 max-md:max-w-full">
                      {q.description}
                    </div>
                  </div>
                  <div className="flex gap-5 my-auto text-base text-center whitespace-nowrap">
                    <div
                      onClick={() => handleAnswer(q.option1)}
                      className="flex gap-2 justify-center items-center px-5 py-2 text-green-600 rounded-xl border-2 border-green-600 border-solid cursor-pointer"
                    >
                      <IoMdHappy className="text-2xl" />
                      <div className="self-stretch my-auto">{q.option1}</div>
                    </div>
                    <div
                      onClick={() => handleAnswer(q.option2)}
                      className="flex gap-2 justify-center items-center px-5 py-2 text-amber-500 rounded-xl border-2 border-amber-500 border-solid cursor-pointer"
                    >
                      <IoMdSad className="text-2xl" />
                      <div className="self-stretch my-auto">{q.option2}</div>
                    </div>
                  </div>
                </div>

                {index === currentQuestions.length - 1 ? (
                  ""
                ) : (
                  <div className="mt-3 max-w-full border-[1px] border-solid border-zinc-300" />
                )}
              </div>
            ))}
          </div>
          <div className="flex justify-center gap-3 mt-8">
            {!isLastQuestionSet && (
              <>
                <button
                  onClick={() =>
                    setCurrentQuestion(currentQuestion - questionsPerPage)
                  }
                  disabled={currentQuestion === 0}
                >
                  {currentQuestion === 0 ? (
                    <img
                      src="/images/dashboard/survey/lt.png"
                      alt="not found"
                      className="w-14 my-auto"
                    />
                  ) : (
                    <img
                      src="/images/dashboard/survey/lt2.png"
                      alt="not found"
                      className="w-14 my-auto"
                    />
                  )}
                </button>
                <button
                  onClick={() =>
                    setCurrentQuestion(currentQuestion + questionsPerPage)
                  }
                  disabled={currentQuestion + questionsPerPage >= questions.length}
                >
                  <img
                    src="/images/dashboard/survey/gt.png"
                    alt="not found"
                    className="w-14 my-auto"
                  />
                </button>
              </>
            )}
            {isLastQuestionSet && (
              <button
                className="bg-[linear-gradient(90deg,#1D68FF_50%,#9A52DE_97.52%)] w-44 text-white px-4 py-3 rounded-lg hover:bg-blue-600"
                onClick={handleSubmit}
              >
                Finish
              </button>
            )}
          </div>
        </div>
        </div>

      )}
    </div>
  );
};

export default Test;


const questions = [
  {
    title: "Provides Support",
    description:
      "Assists others, provide others with hope, courage, or confidence",
    option1: "Most",
    option2: "Least",
  },
  {
    title: "Consistent Approach",
    description:
      "You are often associated with being Steady, uniform, relaible in projects",
    option1: "Most",
    option2: "Least",
  },
  {
    title: "Aspiring",
    description:
      "Your near ones often call you as Ambitious, achieving who sets high goals",
    option1: "Most",
    option2: "Least",
  },
  {
    title: "Consistent Approach",
    description:
      "You are often associated with being Steady, uniform, relaible in projects",
    option1: "Most",
    option2: "Least",
  },
  {
    title: "Aspiring",
    description:
      "Your near ones often call you as Ambitious, achieving who sets high goals",
    option1: "Most",
    option2: "Least",
  },
  {
    title: "Aspiring",
    description:
      "Your near ones often call you as Ambitious, achieving who sets high goals",
    option1: "Most",
    option2: "Least",
  },
  {
    title: "Consistent Approach",
    description:
      "You are often associated with being Steady, uniform, relaible in projects",
    option1: "Most",
    option2: "Least",
  },
  {
    title: "Provides Support",
    description:
      "Assists others, provide others with hope, courage, or confidence",
    option1: "Most",
    option2: "Least",
  },
  {
    title: "Consistent Approach",
    description:
      "You are often associated with being Steady, uniform, relaible in projects",
    option1: "Most",
    option2: "Least",
  },
  {
    title: "Aspiring",
    description:
      "Your near ones often call you as Ambitious, achieving who sets high goals",
    option1: "Most",
    option2: "Least",
  },
  {
    title: "Consistent Approach",
    description:
      "You are often associated with being Steady, uniform, relaible in projects",
    option1: "Most",
    option2: "Least",
  },
  {
    title: "Aspiring",
    description:
      "Your near ones often call you as Ambitious, achieving who sets high goals",
    option1: "Most",
    option2: "Least",
  },
  {
    title: "Aspiring",
    description:
      "Your near ones often call you as Ambitious, achieving who sets high goals",
    option1: "Most",
    option2: "Least",
  },
  {
    title: "Consistent Approach",
    description:
      "You are often associated with being Steady, uniform, relaible in projects",
    option1: "Most",
    option2: "Least",
  },
  {
    title: "Provides Support",
    description:
      "Assists others, provide others with hope, courage, or confidence",
    option1: "Most",
    option2: "Least",
  },
  {
    title: "Consistent Approach",
    description:
      "You are often associated with being Steady, uniform, relaible in projects",
    option1: "Most",
    option2: "Least",
  },
  {
    title: "Aspiring",
    description:
      "Your near ones often call you as Ambitious, achieving who sets high goals",
    option1: "Most",
    option2: "Least",
  },
  {
    title: "Consistent Approach",
    description:
      "You are often associated with being Steady, uniform, relaible in projects",
    option1: "Most",
    option2: "Least",
  },
  {
    title: "Aspiring",
    description:
      "Your near ones often call you as Ambitious, achieving who sets high goals",
    option1: "Most",
    option2: "Least",
  },
  {
    title: "Aspiring",
    description:
      "Your near ones often call you as Ambitious, achieving who sets high goals",
    option1: "Most",
    option2: "Least",
  },
  {
    title: "Consistent Approach",
    description:
      "You are often associated with being Steady, uniform, relaible in projects",
    option1: "Most",
    option2: "Least",
  },
  {
    title: "Provides Support",
    description:
      "Assists others, provide others with hope, courage, or confidence",
    option1: "Most",
    option2: "Least",
  },
  {
    title: "Consistent Approach",
    description:
      "You are often associated with being Steady, uniform, relaible in projects",
    option1: "Most",
    option2: "Least",
  },
  {
    title: "Aspiring",
    description:
      "Your near ones often call you as Ambitious, achieving who sets high goals",
    option1: "Most",
    option2: "Least",
  },
  {
    title: "Consistent Approach",
    description:
      "You are often associated with being Steady, uniform, relaible in projects",
    option1: "Most",
    option2: "Least",
  },
  {
    title: "Aspiring",
    description:
      "Your near ones often call you as Ambitious, achieving who sets high goals",
    option1: "Most",
    option2: "Least",
  },
  {
    title: "Aspiring",
    description:
      "Your near ones often call you as Ambitious, achieving who sets high goals",
    option1: "Most",
    option2: "Least",
  },

];
