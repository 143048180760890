import React from "react";
import { Link } from "react-router-dom";

function TermsCondition() {
  return (
    <div
      style={{ backgroundImage: "url(/images/auth/termsCondition-bg.png)" }}
      className="bg-fixed bg-top bg-cover bg-no-repeat min-w-[320px] min-h-[100vh] md:p-20 max-md:p-10"
    >
   
  <div className="flex relative flex-col p-10 w-full rounded-3xl bg-white bg-opacity-80 text-[#989898]  max-md:max-w-full">
    <div className="flex flex-wrap gap-5  max-w-full text-4xl text-green-600">
       
     <Link to="/SignUp">
      <img
        loading="lazy"
        src="/images/auth/lt.png"
        className="object-contain shrink-0 rounded-2xl aspect-square w-[60px]"
      />
      </Link>
      <div className="flex justify-center font-bebas tracking-[2.55px] w-full max-md:text-3xl max-md:mt-6  mb-5">TERMS OF USE</div>
    </div>
    <div className=" md:ml-20 max-md:mt-6  max-md:max-w-full">
      Lorem ipsum dolor sit amet consectetur. Posuere bibendum pellentesque at
      sit integer tristique maecenas. Tristique at vitae purus scelerisque
      morbi eu nunc porta id. Pellentesque in massa id faucibus. Eget ac id et
      id neque nulla odio. Lorem quis non nulla sed sed nibh maecenas quam.
      Arcu rhoncus tincidunt bibendum sed. Pellentesque vel egestas proin non.
      A nisl vehicula ut purus etiam sit viverra sed consectetur. Lorem ipsum
      dolor sit amet consectetur. Posuere bibendum pellentesque at sit integer
      tristique maecenas. Tristique at vitae purus scelerisque morbi eu nunc
      porta id. Pellentesque in massa id faucibus. Eget ac id et id neque
      nulla odio. Lorem quis non nulla sed sed nibh maecenas quam. Arcu
      rhoncus tincidunt bibendum sed. Pellentesque vel egestas proin non. A
      nisl vehicula ut purus etiam sit viverra sed consectetur.
    </div>
    <div className="mt-5 md:ml-20 max-md:max-w-full">
      Lorem ipsum dolor sit amet consectetur. Posuere bibendum pellentesque at
      sit integer tristique maecenas. Tristique at vitae purus scelerisque
      morbi eu nunc porta id. Pellentesque in massa id faucibus. Eget ac id et
      id neque nulla odio. Lorem quis non nulla sed sed nibh maecenas quam.
      Arcu rhoncus tincidunt bibendum sed. Pellentesque vel egestas proin non.
      A nisl vehicula ut purus etiam sit viverra sed consectetur. Lorem ipsum
      dolor sit amet consectetur. Posuere bibendum pellentesque at sit integer
      tristique maecenas. Tristique at vitae purus scelerisque morbi eu nunc
      porta id. Pellentesque in massa id faucibus. Eget ac id et id neque
      nulla odio. Lorem quis non nulla sed sed nibh maecenas quam. Arcu
      rhoncus tincidunt bibendum sed. Pellentesque vel egestas proin non. A
      nisl vehicula ut purus etiam sit viverra sed consectetur.
    </div>
    <div className="mt-5 md:ml-20 max-md:mb-2.5 max-md:max-w-full">
      Lorem ipsum dolor sit amet consectetur. Posuere bibendum pellentesque at
      sit integer tristique maecenas. Tristique at vitae purus scelerisque
      morbi eu nunc porta id. Pellentesque in massa id faucibus. Eget ac id et
      id neque nulla odio. Lorem quis non nulla sed sed nibh maecenas quam.
      Arcu rhoncus tincidunt bibendum sed. Pellentesque vel egestas proin non.
      A nisl vehicula ut purus etiam sit viverra sed consectetur. Lorem ipsum
      dolor sit amet consectetur. Posuere bibendum pellentesque at sit integer
      tristique maecenas. Tristique at vitae purus scelerisque morbi eu nunc
      porta id. Pellentesque in massa id faucibus. Eget ac id et id neque
      nulla odio. Lorem quis non nulla sed sed nibh maecenas quam. Arcu
      rhoncus tincidunt bibendum sed. Pellentesque vel egestas proin non. A
      nisl vehicula ut purus etiam sit viverra sed consectetur.
    </div>
  </div>

    </div>
  );
}

export default TermsCondition;
