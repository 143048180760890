import React from 'react'

const Subscriptions = () => {
  return (
    <div>
      Subscriptions
    </div>
  )
}

export default Subscriptions
