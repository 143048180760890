import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Slick1 = () => {
  var settings = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    pauseOnHover: true,
    arrows: false,
    appendDots: (dots) => (
      <div
        style={{
         
          color: "red",
          borderRadius: "10px",
          padding: "10px",
        }}
      >
        <ul style={{ margin: "0px" }}> {dots} </ul>
      </div>
    ),
    customPaging: (i) => (
      <div
        style={{
          width: "30px",
          color: "white",
          border: "1px white solid",
          borderRadius: "100%",
          marginTop: "-20px 0px 30px 0px",
        }}
      >
        {i + 1}
      </div>
    ),
  };
  return (
    <div className="w-full text-white pb-20 pr-16 tracking-wider">
      <Slider {...settings}>
        <div className="">
          <div>
            <img src="/images/auth/quotes.png" alt="not" className="pl-0 w-[90px]" />
          </div>
          <div style={{fontStyle:"italic"}} className="pl-10">
            Lorem ipsum dolor sit amet consectetur. Cursus faucibus amet massa
            amet semper. Lorem ipsum dolor sit amet consectetur. Cursus faucibus
            amet massa amet semper.
          </div>
          <div className="pl-10 text-[#FF9C1F] font-medium mt-2">Ariel Tungsten</div>
        </div>
        <div className="">
          <div>
            <img src="/images/auth/quotes.png" alt="not" className="pl-0 w-[90px]" />
          </div>
            <div style={{fontStyle:"italic"}} className="pl-10 ">
            Lorem ipsum dolor sit amet consectetur. Cursus faucibus amet massa
            amet semper. Lorem ipsum dolor sit amet consectetur. Cursus faucibus
            amet massa amet semper.
          </div>
          <div className="pl-10 text-[#FF9C1F] font-medium mt-2">Ariel Tungsten</div>
        </div>
        <div className="">
          <div>
            <img src="/images/auth/quotes.png" alt="not" className="pl-0 w-[90px]" />
          </div>
          <div style={{fontStyle:"italic"}} className="pl-10">
            Lorem ipsum dolor sit amet consectetur. Cursus faucibus amet massa
            amet semper. Lorem ipsum dolor sit amet consectetur. Cursus faucibus
            amet massa amet semper.
          </div>
          <div className="pl-10 text-[#FF9C1F] font-medium mt-2">Ariel Tungsten</div>
        </div>
      </Slider>
    </div>
  );
};

export default Slick1;
