import React, { useEffect, useState } from "react";
import HeaderDash from "./components/HeaderDash";
import MicrocredentialsData from "./components/MicrocredentialsData";
import CommentSection from './components/CommentSection'

const Dashboard = ({ rating, setRating, setActiveTab }) => {
  const [showModal, setShowModal] = useState(true);

  return (
    <main className="p-4 min-w-[320px]">
      <HeaderDash setActiveTab={setActiveTab} />
      <div className="mt-8 max-md:mr-2.5 max-lg:max-w-full">
        <div className="flex gap-5 max-lg:flex-col">
          <div className="flex flex-col w-[67%] max-md:ml-0 max-lg:w-full">
            <div className="flex flex-col mt-1.5 w-full max-md:mt-8 max-md:max-w-full">
              <div className="flex flex-wrap items-start self-start ml-6 text-2xl font-semibold leading-none text-green-600">
                <img
                  loading="lazy"
                  src="/images/dashboard/quote-right.png"
                  className="object-contain shrink-0 self-start -mt-3  aspect-square w-[25px]"
                />
                <div className="flex-auto self-start max-md:max-w-full">
                  Dream big, strive for greatness!
                </div>
                <img
                  loading="lazy"
                  src="/images/dashboard/quote-left.png"
                  className="object-contain shrink-0 self-end mt-4 aspect-square w-[25px]"
                />
              </div>
              <div className="flex flex-col pt-5 pb-4 pl-8 h-full min-h-[50vh] mt-5 w-full bg-white rounded-xl shadow-2xl max-md:pl-5 max-md:max-w-full">
                <div className="flex flex-wrap gap-5 justify-between mr-6 max-md:mr-2.5 max-md:max-w-full">
                  <div className="text-[21px] font-bebas tracking-[1px] text-slate-950">
                    Industries Based On Superpowers
                  </div>
                  <div className="flex gap-2.5 self-start">
                    <img
                      loading="lazy"
                      src="/images/dashboard/lt2.png"
                      className="object-contain shrink-0 aspect-square w-[27px]"
                    />
                    <img
                      loading="lazy"
                      src="/images/dashboard/gt2.png"
                      className="object-contain shrink-0 aspect-square w-[27px]"
                    />
                  </div>
                </div>
                <div className="gap-5 mt-4 m-auto ">
                  <img
                    loading="lazy"
                    src="/images/dashboard/search.png"
                    className="object-contain shrink-0 aspect-square"
                  />
                  <button
                    onClick={() => setShowModal(true)}
                    style={{
                      background:
                        "linear-gradient(90deg, #1D68FF 0%, #9A52DE97 100%)",
                    }}
                    className="px-4 py-3.5 mt-5 text-base text-center text-white rounded-2xl max-md:px-5 max-md:max-w-full"
                  >
                    Complete The Exercise
                  </button>
                </div>
              </div>
              <div className="flex flex-col px-5 py-7 mt-5 w-full  bg-white rounded-xl shadow-2xl max-md:px-5 max-md:max-w-full">
                <div className="flex flex-wrap gap-5 justify-between max-w-full">
                  <div className="text-[21px] font-bebas tracking-[1px] text-slate-950">
                    MICROCREDENTIALS
                  </div>
                  <button onClick={()=>setActiveTab(4)} className="my-auto text-sm text-stone-500">
                    View all
                  </button>
                </div>
                <div className="mt-4">
                  {MicrocredentialsData.slice(0, 3).map((item) => {
                    return (
                      <div className="flex flex-wrap gap-5 mt-3  border-[1px] pl-1 pr-2 rounded-lg">
                        <div className="flex justify-between w-full max-md:flex-wrap gap-x-16 gap-y-5 items-center bg-white max-md:max-w-full">
                          <div className="flex gap-4 text-black">
                            <img
                              loading="lazy"
                              src={item.img}
                              className=" h-[55px] md:my-auto max-md:mt-1 w-[55px]"
                            />
                            <div className="flex flex-col max-md:max-w-full">
                              <div className="self-start text-base font-medium">
                                {item.name}
                              </div>
                              <div className="text-sm max-md:max-w-full">
                                {item.info}
                              </div>
                            </div>
                          </div>
                          <div className="flex gap-3 whitespace-nowrap  my-auto text-sm text-neutral-700">
                            <div className="text-lg text-blue-500">
                              {" "}
                              {item.logo}
                            </div>
                            <div>{item.enrolled}</div>
                          </div>
                          <div className="">{item.next}</div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-col w-[33%] my-auto max-md:ml-0  max-lg:w-full">
           <CommentSection/>
          </div>
        </div>
      </div>

      {showModal ? (
        <>
          <div className="justify-center items-center  flex overflow-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-auto my-6 mx-auto max-w-3xl">
              {/*content*/}
              <div className="border-0 rounded-lg shadow-lg mx-5 relative flex flex-col bg-white outline-none focus:outline-none">
                <div className=" px-10 py-5 max-md:px-5">
                  <div className="flex justify-center">
                    <img
                      src="/images/dashboard/exercise.png"
                      alt="not found"
                      className=""
                    />
                  </div>
                  <div>
                    <div className="z-10 self-center uppercase flex justify-center text-4xl max-md:text-2xl font-bebas tracking-[1px] mt-4 text-green-600">
                      Complete the exercise
                    </div>
                    <div className="mx-auto text-center px-5  mt-3 text-base tracking-tight self-center leading-4 text-neutral-800">
                      Confused about your future career? Get to know <br/> about your 
                      superpowers by taking the test.
                    </div>
                  </div>
                  <div className="flex justify-center gap-3 mt-5 w-full text-sm">
                    <button
                      onClick={() => setShowModal(false)}
                      className="gap-2.5 self-stretch px-7 py-4 text-blue-600 whitespace-nowrap rounded-2xl border border-blue-600 border-solid min-h-[55px] max-md:px-5"
                    >
                      Cancel
                    </button>
                    <button
                      onClick={() => {
                        setShowModal(false);
                        setActiveTab(2); // Switch to Survey tab
                      }}
                      className="gap-2.5 self-stretch px-7 py-4 text-white rounded-2xl bg-[linear-gradient(90deg,#1D68FF_50%,#9A52DE_97.52%)] min-h-[55px] max-md:px-5"
                    >
                      Get Started
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-30 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </main>
  );
};

export default Dashboard;

const CommentPeople = [
  {
    img: "/images/dashboard/user4.png",
    name: "Signe Frank",
    time: "10 Min ago",
    description:
      " The good news about dating your best friend is that you can simply enjoy the excitement of taking.",
  },
  {
    img: "/images/dashboard/user3.png",
    name: "Signe Frank",
    time: "10 Min ago",
    description:
      " The good news about dating your best friend is that you can simply enjoy the excitement of taking.",
  },
];
