import React, { useState } from "react";
import Box from "@mui/material/Box";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import { FaUser } from "react-icons/fa";
import { IoMdMail } from "react-icons/io";
import { FaSchool } from "react-icons/fa";
import { IoIosArrowDown } from "react-icons/io";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import IconButton from "@mui/material/IconButton";
import { Link, useNavigate } from "react-router-dom";

function Individual() {
  const navigate = useNavigate();
  const handleSendOtp = () => {
    navigate("/Otp", {
      state: { type: "IndividualOtp", data: { button: "Submit" } },
    });
  };
  const goToLogin = () => {
    navigate("/", {});
  };
  const [showPasswords, setShowPasswords] = useState({
    password1: false,
    password2: false,
  });
  const handleClickShowPassword = (field) => {
    setShowPasswords((prevState) => ({
      ...prevState,
      [field]: !prevState[field],
    }));
  };
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  // form handling
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    schoolName: "",
    schoolDist: "",
    createPass: "",
    confirmPass: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission
    console.log(formData);
  };
  return (
    <div>
      <form onSubmit={handleSubmit}>
        <Box
          component="form"
          sx={{
            "& .MuiTextField-root": { marginTop: 1.5 },
            "& .MuiInput-underline:hover:before": {
              borderBottom: "none", // No border bottom on hover
            },
            "& .MuiInputBase-input::placeholder": {
              fontSize: "14px", // Adjust placeholder font size here
            },
            "& .MuiFormLabel-root": {
              padding: "15px 3px 0px 21px", // Adjust padding as needed
              fontSize: "17px",
              color: "black",
            },
            "& .MuiInputBase-root": {
              padding: "10px 0px 3px 15px", // Optional: Adjust input padding as needed
            },
            "& .MuiInput-underline:before": {
              borderBottom: "none",
            },
            "& .MuiInput-underline:after": {
              borderBottom: "none",
            },
            "& .MuiInput-underline:hover:before": {
              borderBottom: "none",
            },
          }}
          noValidate
          autoComplete="off"
        >
          <TextField
            id="input-with-icon-textfield"
            label="Full Name"
            placeholder="John Doe"
            // value={formData.name}
            // onChange={handleChange}
            InputLabelProps={{
              shrink: true,
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end" sx={{ margin: "0px 20px" }}>
                  <FaUser />
                </InputAdornment>
              ),
            }}
            variant="standard"
            className="bg-white w-full rounded-xl pt-10 "
          />
          <TextField
            id="input-with-icon-textfield"
            label="Email"
            placeholder="johndoe@gmail.com"
            // value={formData.email}
            // onChange={handleChange}
            InputLabelProps={{
              shrink: true,
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end" sx={{ margin: "0px 20px" }}>
                  <IoMdMail />
                </InputAdornment>
              ),
            }}
            variant="standard"
            className="bg-white w-full rounded-xl pt-10"
          />
          <TextField
            id="input-with-icon-textfield"
            label="School Name"
            placeholder="John High School"
            // value={formData.schoolName}
            // onChange={handleChange}
            InputLabelProps={{
              shrink: true,
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end" sx={{ margin: "0px 20px" }}>
                  <FaSchool />
                </InputAdornment>
              ),
            }}
            variant="standard"
            className="bg-white w-full rounded-xl pt-10"
          />
          <TextField
            id="input-with-icon-textfield"
            label="School District"
            placeholder="Clark County District"
            // value={formData.schoolDist}
            // onChange={handleChange}
            InputLabelProps={{
              shrink: true,
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end" sx={{ margin: "0px 20px" }}>
                  <IoIosArrowDown />
                </InputAdornment>
              ),
            }}
            variant="standard"
            className="bg-white w-full rounded-xl pt-10"
          />

          <TextField
            variant="standard"
            label="Create New Password"
            placeholder="***********"
            type={showPasswords.password1 ? "text" : "password"}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end" sx={{ margin: "0px 8px" }}>
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => handleClickShowPassword("password1")}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {showPasswords.password1 ? (
                      <VisibilityOff />
                    ) : (
                      <Visibility />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            InputLabelProps={{
              shrink: true,
            }}
            className="bg-white w-full rounded-xl pt-10"
          />
          <TextField
            variant="standard"
            label="Confirm Password"
            placeholder="***********"
            type={showPasswords.password2 ? "text" : "password"}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end" sx={{ margin: "0px 8px" }}>
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => handleClickShowPassword("password2")}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {showPasswords.password2 ? (
                      <VisibilityOff />
                    ) : (
                      <Visibility />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            InputLabelProps={{
              shrink: true,
            }}
            className="bg-white w-full rounded-xl pt-10"
          />
        </Box>
        <div className="flex gap-2 justify-center mt-6 max-w-full">
          <div className="flex flex-col  p-0.5">
            <input type="checkbox" className="w-5 h-5" id="" name="" value="" />
          </div>
          <div className="text-base leading-6 ">
            I agree with 
            <buttton className="text-blue-600 pl-1 cursor-pointer">
             <Link to="/terms-conditions">terms & conditions</Link> 
            </buttton>
          </div>
        </div>
        <button
          onClick={handleSendOtp}
          style={{
            background: "linear-gradient(90deg, #1D68FF 0%, #9A52DE97 100%)",
          }}
          className="px-4 py-3.5 mt-5 w-full text-base text-center text-white rounded-2xl max-md:px-5 max-md:max-w-full"
        >
          Send OTP
        </button>
        <div className="flex justify-center mt-7  text-base text-slate-950">
          Already have an account?{" "}
          <button
            onClick={() => goToLogin()}
            className="font-medium text-blue-600 pl-1 cursor-pointer"
          >
            Login
          </button>
        </div>
      </form>
    </div>
  );
}

export default Individual;
