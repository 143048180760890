import React, { useState, useEffect } from "react";

function ReportProcessingPlan() {
  const [rocketPosition, setRocketPosition] = useState(window.innerHeight); // Start at the bottom of the screen
  const [showMessage, setShowMessage] = useState(true);

  useEffect(() => {
    let intervalId = setInterval(() => {
      setRocketPosition((prevPosition) => {
        const newPosition = prevPosition - 10; // Increase movement per update

        // Check if the rocket is near the message
        if (newPosition <= 80) {
          setShowMessage(false); // Hide the message
        }

        if (newPosition <= 50) { // Stop near the top
          clearInterval(intervalId);
        }

        return newPosition;
      });
    }, 30); // Decrease the interval time

    return () => clearInterval(intervalId);
  }, []);

  // Calculate message position based on rocket position
  const messagePosition = rocketPosition < 80 ? rocketPosition + 20 : window.innerHeight / 2;

  return (
    <div className="min-h-screen flex flex-col items-center justify-center bg-gradient-to-b from-purple-500 to-blue-500 relative">
      <div
        className="absolute"
        style={{ top: `${rocketPosition}px` }}
      >
        <img
          src="/images/dashboard/survey/rocket.png"
          alt="Rocket"
          className="w-20"
        />
      </div>
      {showMessage && (
        <div
          className="absolute flex flex-col items-center justify-center text-white text-lg font-bold"
          style={{ top: `${messagePosition}px` }} // Position message based on rocket
        >
          <div className="text-2xl font-bold uppercase">Whohoo!</div>
          <div>Your reports are getting ready!</div>
        </div>
      )}
    </div>
  );
}

export default ReportProcessingPlan;
