import * as React from "react";
import HeaderDash from "./components/HeaderDash";
import CommentSection from "./components/CommentSection";
import { IoIosSearch } from "react-icons/io";
import { GoPencil } from "react-icons/go";
import { RiDeleteBinLine } from "react-icons/ri";
import Textarea from "@mui/joy/Textarea";
import Typography from "@mui/joy/Typography";
import Filter from "./components/Filter";

function Celebrate({setActiveTab}) {
  const [viewPost, setViewPost] = React.useState(false);
  const [showModal, setShowModal] = React.useState(false);
  const [showModalEditPost, setShowModalEditPost] = React.useState(false);
  const [showModalDeletePost, setShowModalDeletePost] = React.useState(false);
  const [activeId, setActiveId] = React.useState(null);
  const [isOpen, setIsOpen] = React.useState(false);
  const postRef = React.useRef();
  const [text, setText] = React.useState("");
  const [text1, setText1] = React.useState("");

  // Example useEffect to simulate fetching existing text
  React.useEffect(() => {
    // Simulate fetching existing text
    const fetchData = async () => {
      // Replace this with actual fetch or logic to get existing text
      const existingText =
        "Lorem ipsum dolor sit amet consectetur. Eget consectetur velit amet pellentesque tellus. Nisl sed sed cras facilisis sed aenean.";
      setText1(existingText);
    };

    fetchData();
  }, []);

  React.useEffect(() => {
    const handleClickOutside = (event) => {
      if (postRef.current && !postRef.current.contains(event.target)) {
        setActiveId(null);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [postRef]);
  return (
    <div className="flex flex-col p-4 w-full rounded-3xl bg-zinc-50 max-md:px-5 max-md:max-w-full">
      <HeaderDash setActiveTab={setActiveTab} />
      <div className="flex flex-wrap gap-5 mt-7 h-fit justify-between max-md:flex-col w-full  max-md:max-w-full">
        <div className="flex justify-between h-fit  pl-4 pr-1 py-1 md:w-[40%] text-sm text-black bg-white rounded-xl shadow-[0px_0px_19px_rgba(0,0,0,0.05)] max-md:max-w-full">
          <div className="flex gap-2 w-[80vw] ">
            <IoIosSearch className=" my-auto  text-2xl text-gray-400 " />
            <input
              type="search"
              name="search-form"
              id="search-form"
              className="w-full outline-none border-none my-auto "
              placeholder="Search feed "
            />
          </div>
          <img
            onClick={() => setIsOpen(true)}
            loading="lazy"
            src="/images/dashboard/celebrate/filter.png"
            className="w-[45px] h-[45px] my-auto  cursor-pointer"
          />
        </div>
        <div
          style={{ scrollbarWidth: "none" }}
          className={`fixed top-0 right-0 h-full z-10 overflow-auto shadow-2xl  transition-transform duration-300 ease-in-out ${
            isOpen ? "translate-x-0" : "translate-x-full"
          }`}
        >
          <Filter setIsOpen={setIsOpen} />
        </div>
        <div
          onClick={() => setViewPost(true)}
          className="flex flex-wrap  gap-5 text-base  max-md:mt-5 text-center max-md:max-w-full"
        >
          <button
            className={`flex flex-auto text-[#1D68FF] justify-center items-center my-auto px-10 py-3 rounded-xl border border-blue-600 border-solid } ${
              viewPost === true ? "hidden" : ""
            }`}
          >
            View My Posts
          </button>
          <button
            onClick={() => setShowModal(true)}
            style={{
              background: "linear-gradient(90deg, #1D68FF 0%, #9A52DE97 100%)",
            }}
            className="flex flex-auto gap-2.5 justify-center items-center px-7 py-3 text-white rounded-xl  max-md:px-5"
          >
            <div className="text-2xl">+</div>
            <div className=" my-auto">Create New Post</div>
          </button>
        </div>
      </div>
      <div className="mt-7 max-md:max-w-full">
        <div className="flex gap-5 max-lg:flex-col">
          <div className="flex flex-col w-[34%] max-md:ml-0 max-lg:w-full">
            <CommentSection />
          </div>
          <div className="flex flex-col w-[66%] max-md:ml-0 max-lg:w-full">
            <div className="flex flex-col w-full max-md:mt-5 max-md:max-w-full">
              <div className="max-md:mr-0.5 max-md:max-w-full">
                <div
                  className="grid grid-cols-2 max-md:grid-cols-1 gap-4"
                  ref={postRef}
                >
                  {posts.map((item) => {
                    return (
                      <div className="flex flex-col  max-md:w-full">
                        <div className="flex flex-col items-start p-3.5 mx-auto w-full text-black bg-white rounded-3xl shadow-2xl  max-md:max-w-full">
                          <div className="flex  relative justify-between items-start w-full">
                            <div className="flex gap-3">
                              <img
                                loading="lazy"
                                src={item.profile}
                                className=" rounded-full w-[62px]"
                              />
                              <div className="flex flex-col my-auto">
                                <div className="self-start text-[15px] font-medium">
                                  {item.name}
                                </div>
                                <div className="text-xs">{item.profession}</div>
                              </div>
                            </div>

                            {item.id == activeId ? (
                              <div className="absolute right-0 top-10">
                                <div className="flex flex-col text-sm ">
                                  <div className="flex flex-col items-start px-4 py-2.5 w-full bg-white rounded-2xl border border-solid border-neutral-200">
                                    <div
                                      onClick={() => setShowModalEditPost(true)}
                                      className="flex gap-2.5 cursor-pointer text-neutral-700"
                                    >
                                      <GoPencil className="text-lg" />
                                      <button>Edit Post</button>
                                    </div>
                                    <div
                                      onClick={() =>
                                        setShowModalDeletePost(true)
                                      }
                                      className="flex gap-2.5 mt-2 cursor-pointer text-rose-600"
                                    >
                                      <RiDeleteBinLine className="text-lg" />
                                      <button>Delete Post</button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ) : (
                              ""
                            )}

                            <button
                              onClick={() => setViewPost(true)}
                              className={`${viewPost === true ? "" : "hidden"}`}
                            >
                              <div
                                className="text-3xl"
                                onClick={() => setActiveId(item.id)}
                              >
                                ...
                              </div>
                              {/* {activeId.includes()} */}
                            </button>
                          </div>
                          <img
                            loading="lazy"
                            src={item.img}
                            className="object-contain self-stretch mt-4 w-full aspect-[1.82] max-md:max-w-full"
                          />
                          <div className="mt-2.5 text-sm leading-6 text-neutral-700">
                            {item.desc}
                          </div>
                          <div className="flex gap-5 mt-3 leading-none whitespace-nowrap">
                            <div className="flex gap-1.5 text-sm">
                              <img
                                loading="lazy"
                                src={item.handImg}
                                className="object-contain shrink-0 aspect-square -translate-y-[2px] w-[26px]"
                              />
                              <div className="my-auto">{item.handCount}</div>
                            </div>
                            <div className="flex gap-1.5 self-start text-base">
                              <img
                                loading="lazy"
                                src={item.chatImg}
                                className="object-contain shrink-0 w-6 aspect-square"
                              />
                              <div>{item.chatCount}</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {showModal ? (
        <>
          <div className="justify-center items-center flex overflow-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-auto my-6 mx-auto max-w-3xl">
              {/*content*/}
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/*header*/}

                <div className="flex flex-col rounded-none">
                  <div className="flex flex-col items-start p-7 w-full bg-white rounded-3xl max-md:p-5 max-md:max-w-full">
                    <div className="flex justify-between w-full">
                      <div className="text-xl my-auto font-medium text-black">
                        Share your thoughts
                      </div>
                      <img
                        onClick={() => setShowModal(false)}
                        loading="lazy"
                        src="/images/dashboard/celebrate/cross.png"
                        className=" my-auto bg-gray-50 cursor-pointer  rounded-full aspect-square w-[35px]"
                      />
                    </div>
                    <div className="mt-5 w-[40vw] ">
                      <Textarea
                        placeholder="What’s on your mind Joe?"
                        value={text}
                        onChange={(event) => setText(event.target.value)}
                        minRows={2}
                        maxRows={4}
                        endDecorator={
                          <Typography
                            level="body-xs"
                            sx={{ ml: "auto", mr: "10px" }}
                          >
                            {text.length} /100
                          </Typography>
                        }
                        sx={{
                          minWidth: 300,

                          borderRadius: "15px",
                          height: "20vh",
                          backgroundColor: "white",
                          boxShadow: "0px 0px 19px 0px rgba(0, 0, 0, 0.05) ",
                          border: "none",
                          outline: "none",
                        }}
                      />
                    </div>
                    <div className="flex flex-wrap gap-5 justify-between mt-5 w-full overflow-x-auto max-md:max-w-full">
                      <div className="flex gap-3.5">
                        <img
                          loading="lazy"
                          src="/images/dashboard/celebrate/add-post1.png"
                          className="w-16 aspect-square"
                        />
                        <img
                          loading="lazy"
                          src="/images/dashboard/celebrate/add-post2.png"
                          className="w-16 aspect-square"
                        />
                        <img
                          loading="lazy"
                          src="/images/dashboard/celebrate/add-post3.png"
                          className="w-16 aspect-square"
                        />
                        <img
                          loading="lazy"
                          src="/images/dashboard/celebrate/add-post.png"
                          className="w-16 aspect-square"
                        />
                      </div>
                    </div>
                    <button
                      style={{
                        background:
                          "linear-gradient(90deg, #1D68FF 0%, #9A52DE97 100%)",
                      }}
                      className="gap-2.5 self-stretch px-7 py-4 mx-5 mt-10 text-base text-white text-center whitespace-nowrap rounded-2xl"
                    >
                      Post
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
      {showModalEditPost ? (
        <>
          <div className="justify-center items-center flex overflow-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-auto my-6 mx-auto max-w-3xl">
              {/*content*/}
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/*header*/}

                <div className="flex flex-col rounded-none">
                  <div className="flex flex-col items-start p-7 w-full bg-white rounded-3xl max-md:p-5 max-md:max-w-full">
                    <div className="flex justify-between w-full">
                      <div className="text-xl my-auto font-medium text-black">
                        Edit Post
                      </div>
                      <img
                        onClick={() => setShowModalEditPost(false)}
                        loading="lazy"
                        src="/images/dashboard/celebrate/cross.png"
                        className=" my-auto bg-gray-50 cursor-pointer  rounded-full aspect-square w-[35px]"
                      />
                    </div>
                    <div className="mt-5 w-[40vw] ">
                      <Textarea
                        value={text1}
                        onChange={(event) => setText1(event.target.value)}
                        minRows={2}
                        maxRows={4}
                        endDecorator={
                          <Typography
                            level="body-xs"
                            sx={{ ml: "auto", mr: "10px" }}
                          >
                            {text.length} /100
                          </Typography>
                        }
                        sx={{
                          minWidth: 300,

                          borderRadius: "15px",
                          height: "20vh",
                          backgroundColor: "white",
                          boxShadow: "0px 0px 19px 0px rgba(0, 0, 0, 0.05) ",
                          border: "none",
                          outline: "none",
                        }}
                      />
                    </div>
                    <div className="flex flex-wrap gap-5 justify-between mt-5 w-full overflow-x-auto max-md:max-w-full">
                      <div className="flex gap-3.5">
                        <img
                          loading="lazy"
                          src="/images/dashboard/celebrate/add-post1.png"
                          className="w-16 aspect-square"
                        />
                        <img
                          loading="lazy"
                          src="/images/dashboard/celebrate/add-post2.png"
                          className="w-16 aspect-square"
                        />
                        <img
                          loading="lazy"
                          src="/images/dashboard/celebrate/add-post3.png"
                          className="w-16 aspect-square"
                        />
                        <img
                          loading="lazy"
                          src="/images/dashboard/celebrate/add-post.png"
                          className="w-16 aspect-square"
                        />
                      </div>
                    </div>
                    <button
                      style={{
                        background:
                          "linear-gradient(90deg, #1D68FF 0%, #9A52DE97 100%)",
                      }}
                      className="gap-2.5 self-stretch px-7 py-4 mx-5 mt-10 text-base text-white text-center whitespace-nowrap rounded-2xl"
                    >
                      Save Post
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
      {showModalDeletePost ? (
        <>
          <div className="justify-center items-center max-md:mx-8 flex overflow-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-auto my-6 mx-auto max-w-3xl">
              {/*content*/}
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/*header*/}

                <div className="flex flex-col rounded-none">
                  <div className="flex flex-col items-start pt-2 px-5 pb-5 md:w-[400px] w-full bg-white rounded-3xl max-md:p-5 max-md:max-w-full">
                    <div className="flex justify-end w-full">
                      <img
                        onClick={() => setShowModalDeletePost(false)}
                        loading="lazy"
                        src="/images/dashboard/celebrate/cross.png"
                        className=" my-auto bg-gray-50 cursor-pointer  rounded-full aspect-square w-[35px]"
                      />
                    </div>
                    <div className="flex justify-center w-full text-2xl font-bebas text-[#FF5271] tracking-[1px]">
                      DELETE POST
                    </div>

                    <div className="mt-2 mx-auto text-[15px] leading-[25px] max-w-[300px] text-center text-neutral-400 ">
                      Are you sure you want to delete post? This action cannot
                      be reversed.
                    </div>
                    <button className=" mx-auto w-[80%] px-10 py-4 mt-7  text-sm text-center text-white whitespace-nowrap bg-[#FF1942] rounded-2xl ">
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </div>
  );
}
export default Celebrate;

const posts = [
  {
    id: 1,
    profile: "/images/dashboard/celebrate/profilePic.png",
    name: "Caroline",
    profession: "High School Name",
    img: "/images/dashboard/celebrate/post4.png",
    desc: "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
    handImg: "/images/dashboard/celebrate/hand.png",
    handCount: "83",
    chatImg: "/images/dashboard/celebrate/comment.png",
    chatCount: "18",
  },
  {
    id: 2,
    profile: "/images/dashboard/celebrate/profilePic.png",
    name: "Caroline",
    profession: "High School Name",
    img: "/images/dashboard/celebrate/post3.png",
    desc: "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
    handImg: "/images/dashboard/celebrate/hand.png",
    handCount: "83",
    chatImg: "/images/dashboard/celebrate/comment.png",
    chatCount: "18",
  },
  {
    id: 3,
    profile: "/images/dashboard/celebrate/profilePic.png",
    name: "Caroline",
    profession: "High School Name",
    img: "/images/dashboard/celebrate/post2.png",
    desc: "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
    handImg: "/images/dashboard/celebrate/hand.png",
    handCount: "83",
    chatImg: "/images/dashboard/celebrate/comment.png",
    chatCount: "18",
  },
  {
    id: 4,
    profile: "/images/dashboard/celebrate/profilePic.png",
    name: "Caroline",
    profession: "High School Name",
    img: "/images/dashboard/celebrate/post1.png",
    desc: "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
    handImg: "/images/dashboard/celebrate/hand.png",
    handCount: "83",
    chatImg: "/images/dashboard/celebrate/comment.png",
    chatCount: "18",
  },
];
