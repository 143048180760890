// import logo from './logo.svg';
import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import SignUp from './auth/SignUp';
import ForgotPass from './auth/ForgotPass';
import Otp from './common/Otp';
import CreatePass from './auth/CreatePass';
import TermsCondition from './auth/TermsCondition';
import Subscriptions from './subscription/Subscriptions';
import Login from './auth/Login';
import ProceedPayment from './subscription/ProceedPayment';
import Congratulation from './subscription/Congratulation';
import AdminIndex from './dashboard/AdminIndex';
import ReportProcessingPlan from './dashboard/components/ReportProcessingPlan';
function App() {
  // const [forgot ,setForgot]=useState(Forgot)
  return (
  <div>
      <Router>
      <Routes>
      <Route path="/" element={<Login />} />
        <Route path="/signUp" element={<SignUp />} />
        <Route path="/forgot-password" element={<ForgotPass/>} />
        <Route path="/otp" element={<Otp />} />
        <Route path="/create-password" element={<CreatePass />} />
        <Route path="/terms-conditions" element={<TermsCondition />} />
     
        <Route path="/subscriptions" element={<Subscriptions />} />
        <Route path="/proceed-payment" element={<ProceedPayment />} /> 
        <Route path="/congratulation" element={<Congratulation />} />
        <Route path="/admin-index" element={<AdminIndex />} />
        
      </Routes>
    </Router>

  </div>
  );
}

export default App;
