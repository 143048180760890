import React, { useState } from "react";
import Box from "@mui/material/Box";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import { IoMdMail } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import Slick1 from "../common/Slick1";
import Otp from "../common/Otp";

const ForgotPass = () => {
  const [count, setCount] = useState(0);
  const navigate = useNavigate();
  const handleSendOtp = () => {
    navigate("/otp", {
      state: {
        type: "ForgotPassword",
        data: { button: "Verify & Create Password" },
      },
    });
  };
  const [formData, setFormData] = useState({
    email: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission and send OTP logic here
    console.log(formData);
    setCount(1); // Switch to the OTP page
  };

  return (
    <div
      style={{ backgroundImage: "url(/images/auth/auth-bg.png)" }}
      className="bg-fixed bg-top bg-cover min-w-[320px] min-h-[100vh]"
    >
      <div className="max-lg:px-16 max-md:px-7 py-5 w-full">
        <div className="flex flex-col justify-center lg:ml-[10%] mt-[10%] w-[32%] max-lg:w-full">
          <img
            loading="lazy"
            src="/images/auth/mainLogo.png"
            className="self-center max-w-full aspect-[0.93] w-[100px]"
          />
          <div className="self-center font-bebas mt-2.5 text-4xl max-md:text-3xl tracking-[2.55px] text-green-600 ">
            FORGOT PASSWORD?
          </div>
          <div className="mt-1.5 text-base text-center text-zinc-500 max-md:max-w-full">
            No worries! Enter your email for the verification process, we will
            send a four-digit code to your email.
          </div>
          <div>
            <form onSubmit={handleSubmit}>
              <Box
                component="div"
                sx={{
                  "& .MuiTextField-root": { marginTop: 1.5 },
                  "& .MuiInput-underline:hover:before": {
                    borderBottom: "none",
                  },
                  "& .MuiInputBase-input::placeholder": {
                    fontSize: "14px",
                  },
                  "& .MuiFormLabel-root": {
                    padding: "15px 3px 0px 21px",
                    fontSize: "17px",
                    color: "black",
                  },
                  "& .MuiInputBase-root": {
                    padding: "10px 0px 3px 15px",
                  },
                  "& .MuiInput-underline:before": {
                    borderBottom: "none",
                  },
                  "& .MuiInput-underline:after": {
                    borderBottom: "none",
                  },
                  "& .MuiInput-underline:hover:before": {
                    borderBottom: "none",
                  },
                }}
                noValidate
                autoComplete="off"
              >
                <TextField
                  id="input-with-icon-textfield"
                  label="Email"
                  placeholder="johndoe@gmail.com"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment
                        position="end"
                        sx={{ margin: "0px 20px" }}
                      >
                        <IoMdMail />
                      </InputAdornment>
                    ),
                  }}
                  variant="standard"
                  className="bg-white w-full rounded-xl pt-10"
                />
              </Box>

              <button
                onClick={handleSendOtp}
                type="submit"
                style={{
                  background:
                    "linear-gradient(90deg, #1D68FF 0%, #9A52DE97 100%)",
                }}
                className="px-4 py-3.5 mt-5 w-full text-base text-center text-white rounded-2xl max-md:px-5 max-md:max-w-full"
              >
                Send OTP
              </button>
            </form>
          </div>
        </div>

        <div className="max-lg:hidden flex items-end fixed right-0 bottom-0 w-[40%]">
          <Slick1 />
        </div>
      </div>
    </div>
  );
};

export default ForgotPass;
