import React, { useState } from "react";
import DashboardWrapper from "../wrapper/DashboardWrapper";
import Dashboard from "./Dashboard";
import Survey from "./Survey";
import JourneyMap from "./JourneyMap";
import Celebrate from "./Celebrate";
import Mentors from "./Mentors";
import CareerPlanning from "./CareerPlanning";
import Subscriptions from "./Subscriptions";
import RateOurApp from "./RateOurApp";
import Microcredentials from "./Microcredentials";
import { useSelector } from "react-redux";
import CtePathways from "./components/careerPlannings/CtePathways";
import MilitaryOption from "./components/careerPlannings/MilitaryOption";
import Entrepreneurship from "./components/careerPlannings/Entrepreneurship";
import ClgOption from "./components/careerPlannings/ClgOption";
import MyProfile from "./profile-components/MyProfile";

const AdminIndex = () => {
  const [activeTab, setActiveTab] = useState(1);
  const cPlan = useSelector((state) => state.careerPlanState.c_plan_answered);
  console.log("current state is ", cPlan);

  const handleNavigateToSurvey = () => {
    setActiveTab(3); // Switch to the Survey tab
  };

  return (
    <DashboardWrapper activeTab={activeTab} setActiveTab={setActiveTab}>
      {activeTab === 1 && (
        <Dashboard
                 setActiveTab={setActiveTab}
        />
      )}
      {activeTab === 2 && <Survey setActiveTab={setActiveTab} />}
      {activeTab === 3 && <JourneyMap setActiveTab={setActiveTab} />}
      {activeTab === 4 && <Microcredentials setActiveTab={setActiveTab} />}
      {activeTab === 5 && <Celebrate setActiveTab={setActiveTab} />}
      {activeTab === 6 && <Mentors setActiveTab={setActiveTab} />}
      {activeTab === 7 && ( !cPlan  && <CareerPlanning onNavigateToSurvey={handleNavigateToSurvey} setActiveTab={setActiveTab} />)}
      {activeTab === 8 && <Subscriptions setActiveTab={setActiveTab} />}
      {activeTab === 9 && <RateOurApp setActiveTab={setActiveTab} />}

      {activeTab === 10 && <CtePathways setActiveTab={setActiveTab}/>}
      {activeTab === 11 && <MilitaryOption setActiveTab={setActiveTab} />} 
      {activeTab === 12 && <Entrepreneurship setActiveTab={setActiveTab} />}
      {activeTab === 13 && <ClgOption setActiveTab={setActiveTab} />}

      {activeTab === 14 && <MyProfile setActiveTab={setActiveTab} />}
    </DashboardWrapper>
  );
};

export default AdminIndex;
