import React, { useState } from "react";
import HeaderDash from "./components/HeaderDash";
import DiscoverInterest from "./components/Discover_Interest";

const CareerPlanning = ({ onNavigateToSurvey ,setActiveTab }) => {
  const [showInterest, setShowInterest] = useState(false);
  const handleFinish = () => {
    setShowInterest(false);
    if (onNavigateToSurvey) onNavigateToSurvey(); // Notify parent to navigate to the Survey page
    
  };
  return (
    <div className="p-4">
      <HeaderDash setActiveTab={setActiveTab} />
      {showInterest === true ? (
        <DiscoverInterest onFinish={handleFinish} />
      ) : (
        <div className="flex flex-col p-4 h-full mt-5 w-full max-md:pl-5  max-md:max-w-full">
          <div className="uppercase text-[#009A39] font-bebas text-3xl max-md:text-2xl tracking-[1px]">
            JOURNEYS MAP
          </div>
          <img
            src="/images/dashboard/career-planning/journey-map-bg.png"
            alt="not found"
            className=" rounded-xl"
          />

          <div className="z-10 mt-8 max-md:max-w-full">
            <div className="flex gap-5 max-md:flex-col">
              <div className="flex flex-col max-md:w-full">
                <div className="flex flex-col w-full text-base text-zinc-600 max-md:mt-10 max-md:max-w-full">
                  <div className="self-start uppercase text-3xl tracking-[1px] font-bebas text-green-600 ">
                    About
                  </div>
                  <div className="mt-3 max-md:mr-2.5 max-md:max-w-full">
                    Sed ut perspiciatis unde omnis iste natus error sit
                    voluptatem accusantium doloremque laudantium, totam rem
                    aperiam, eaque ipsa quae ab illo inventore veritatis et
                    quasi architecto beatae vitae dicta sunt explicabo. Nemo
                    enim ipsam voluptatem. Sed ut perspiciatis unde omnis iste
                    natus error sit voluptatem accusantium doloremque
                    laudantium, totam rem aperiam, eaque ipsa quae ab illo
                    inventore veritatis et ipsa quae ab illo inventor. Sed ut
                    perspiciatis unde omnis iste natus error sit voluptatem
                    accusantium doloremque laudantium, totam rem aperiam, eaque
                    ipsa quae ab illo inventore veritatis et quasi architecto
                    beatae vitae dicta sunt explicabo. Nemo enim ipsam
                    voluptatem. Sed ut perspiciatis unde omnis iste natus error
                    sit voluptatem accusantium doloremque laudantium, totam rem
                    aperiam, eaque ipsa quae ab illo inventore veritatis et ipsa
                    quae ab illo inventor
                  </div>
                  <div className="mt-3 max-md:mr-2.5 max-md:max-w-full">
                    Sed ut perspiciatis unde omnis iste natus error sit
                    voluptatem accusantium doloremque laudantium, totam rem
                    aperiam, eaque ipsa quae ab illo inventore veritatis et
                    quasi architecto beatae vitae dicta sunt explicabo. Nemo
                    enim ipsam voluptatem. Sed ut perspiciatis unde omnis iste
                    natus error sit voluptatem accusantium doloremque
                    laudantium, totam rem aperiam, eaque ipsa quae ab illo
                    inventore veritatis et ipsa quae ab illo inventor
                  </div>
                  <button onClick={()=>setShowInterest(true)} className="gap-2.5 self-center px-5 md:w-[40%] mt-10 py-4 text-white rounded-2xl bg-[linear-gradient(90deg,#1D68FF_50%,#9A52DE_97.52%)] ">
                    Discover Your Interests
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CareerPlanning;
